import { gql } from '@apollo/client';
import {
  AdminDiamondFragment,
  CustomerDiamondFragment,
  DiamondFragment,
  OrderDiamondFragment,
} from './DiamondFragment';
import {
  MeleeDescriptionFragment,
  MeleeFragment,
  MeleeUploadInvoiceFragment,
} from './MeleeFragment';
import {
  CertificateFragment,
  CertificateInfoFragment,
} from './CertificateFragment';
import { NoteFragment } from './NoteFragment';
import { GemstoneCertificate, GemstoneFragment } from './GemstoneOfferFragment';
import { NivodaExpressItemFragment } from './NivodaExpressFragment';
import {
  JewelleryFileFragment,
  JewelleryFragment,
  JewelleryMountFragment,
  JewelleryStoneFragment,
} from './JewelleryFragment';

export const Base = gql`
  fragment BaseOIF on OrderItem {
    id
    order {
      id
      order_date
    }
    ProductType
    carats
    pieces
    destination {
      id
      address1
      address2
      postalCode
      state
      city
      name
      country
    }
    origin {
      id
      name
      country
      __typename
    }
    buyer {
      id
      firstName
      lastName
      email
      company {
        id
        name
        qc_pref
      }
    }
    comment
    wdcComment
    status
    endCustomerName
    order_number
    exchange_rate
    canada_mark_required
    dtl_required
  }
`;

export const AdminReturnOrderItemFragment = gql`
  fragment AdminReturnOrderItem on OrderItem {
    id
    order_number
    order_date
    buyer {
      id
      firstName
      company {
        id
        name
        returns_used
        all_in_pricing
        company_settings {
          CompanyId
          company_code
          customer_order_no_on_bc
          max_allowed_returns
        }
        qc_pref
      }
    }
    buy_price
    buy_discount
    buy_price_confirmed
    collectedDate
    destination {
      id
      country
    }
    FbnId
    FbnType
    FbnItem {
      ...NivodaExpressItemFragment
    }
    origin_country
    origin_city
    parcel_paper
    seller {
      id
      company {
        id
        name
        country
      }
    }
    ProductType
    Product {
      ... on Diamond {
        id
        availability
        supplier {
          id
          name
          country
          city
        }
        supplierStockId
        diamondSupplierStockId
        certificate {
          ...CertificateInfo
        }
        blue
        brown
        eyeClean
        gray
        green
        milky
      }
      ... on Melee {
        ...MeleeDescription
        supplierStockId
        total_price
        price_per_carat
        pieces
        IsParentMeleeCollection
      }
      ... on Gemstone {
        id
        supplierStockId
        availability
        last_enquiry_answered
        has_bgmec_enquiry
        has_media_enquiry
        GemstoneCertificate: certificate {
          id
          ...GemstoneCertificate
        }
        location {
          id
          name
          country
        }
        supplier {
          id
          name
          country
        }
      }
    }
    invoice {
      id
    }
    order {
      id
      order_date
    }
    carats
    pieces
    customer_order_number
    return_option
    shipping_reference_number
    status
  }
  ${CertificateInfoFragment}
  ${NivodaExpressItemFragment}
  ${MeleeDescriptionFragment}
  ${GemstoneCertificate}
`;

export const AdminAccountingOrderItemFragment = gql`
  fragment AdminAccountingOrderItem on OrderItem {
    id
    origin_country
    seller {
      id
      is_supplier_invoicing_enabled
      company {
        id
        name
      }
    }
    supplier_payment {
      payment_date
    }
    order_date
    Product {
      ... on Diamond {
        id
        supplier {
          id
          name
          payment_terms {
            payment_term_type
            payment_term_days
            payment_term_pay_day
            supplier_credit_limit_usd
            payment_term_interval
            location
          }
        }
        supplierStockId
        diamondSupplierStockId
        certificate {
          ...CertificateFragment
        }
      }
      ... on Melee {
        ...MeleeUploadInvoiceFragment
        total_price
        carats
      }
      ... on Gemstone {
        id
        supplier {
          id
          name
          payment_terms {
            payment_term_type
            payment_term_days
            payment_term_pay_day
            payment_term_interval
            location
          }
        }
        supplierStockId
        GemstoneCertificate: certificate {
          ...GemstoneCertificate
        }
      }
    }
    supplier_invoice {
      id
      currency
      due_date
      invoiced_at
      invoice_number
      exchange_rate
      total_amount
      total_amount_tax
      usd_amount
      name_on_invoice
      vat_number
      payment_discount
      volume_discount
      shipping_charges
      other_charges
      from_country
      tax_amount
    }
    carats
    pieces
    ProductType
    invoice {
      id
      currency
      exchange_rate
      invoice_number
      usd_amount
    }
    order_number
    rupee_rate
    collectedDate
    order {
      id
      order_date
    }
    buy_price
    Qc {
      id
      status
    }
  }
  ${CertificateFragment}
  ${MeleeUploadInvoiceFragment}
  ${GemstoneCertificate}
`;
// Customer facing order item fragment
export const CustomerOrderItem = gql`
  fragment CustomerOrderItem on OrderItem {
    ...BaseOIF
    Product {
      ... on Diamond {
        ...CustomerDiamond
      }
      ... on Melee {
        ...MeleeFragment
        carats
        carats_ordered
        child_melee_collections {
          supplierStockId
          total_price
          price_per_carat
          IsParentMeleeCollection
        }
        total_price
        price_per_carat
        IsParentMeleeCollection
      }
      ... on Gemstone {
        ...GemstoneFragment
      }
    }
    promisedDeliveryDate
    deliveryDeadline
    deliveryDate
    expectedDeliveryDate
    expectedShippingDate
    shippingDate
    expectedCollectionDate
    collectedDate
    transitDate
    customer_order_number
    service_fee
    insurance_cost
  }
  ${Base}
  ${CustomerDiamondFragment}
  ${MeleeFragment}
  ${GemstoneFragment}
`;

export const AdminOrderItemFragment = gql`
  fragment AdminOrderItem on OrderItem {
    ...BaseOIF
    internal_order
    Product {
      ... on Diamond {
        ...AdminDiamond
        diamondSupplierStockId
      }
      ... on Melee {
        ...MeleeFragment
        original_carats_ordered
        child_melee_collections {
          ...MeleeFragment
          carats
          supplierStockId
          total_price
          price_per_carat
          IsParentMeleeCollection
        }
        #        supplierStockId
        total_price
        price_per_carat
        IsParentMeleeCollection
      }
      ... on Gemstone {
        ...GemstoneFragment
        gemstoneSupplierStockId
      }
      ... on Jewellery {
        ...JewelleryFragment
        supplier {
          id
          name
        }
        mounts {
          ...JewelleryMountFragment
        }
        stones {
          ...JewelleryStoneFragment
        }
        jewellery_files {
          ...JewelleryFileFragment
        }
      }
    }
    order_method
    promisedDeliveryDate
    deliveryDeadline
    deliveryDate
    expectedDeliveryDate
    expectedShippingDate
    shippingDate
    expectedCollectionDate
    collectedDate
    transitDate
    customer_order_number
    service_fee
    insurance_cost
  }
  ${Base}
  ${AdminDiamondFragment}
  ${MeleeFragment}
  ${GemstoneFragment}
  ${JewelleryFragment}
  ${JewelleryMountFragment}
  ${JewelleryStoneFragment}
  ${JewelleryFileFragment}
`;

export const LOSFragment = gql`
  fragment LOSFragment on OrderItem {
    id
    los_confirmed {
      status
      text
    }
    los_invoiced {
      status
      text
    }
    los_collected {
      status
      text
    }
    los_payment_received {
      status
      text
    }
    los_supplier_paid {
      status
      secondary_text
      text
    }
    los_delivered {
      status
      text
    }
    los_notes {
      status
      text
    }
    los_extra {
      dtl
      cm
    }
  }
`;

export const CustomerOrderItemFragment = gql`
  fragment CustomerOrderItem on OrderItem {
    id
    Product {
      ... on Diamond {
        ...OrderDiamond
      }
      ... on Melee {
        ...MeleeFragment
        carats_ordered
      }
      ... on Gemstone {
        ...GemstoneFragment
      }
    }
    offer {
      id
      price_per_carat
    }
    carats
    pieces
    destination {
      id
      name
      city
      country
    }
    invoice {
      id
      invoice_number
    }
    buyer {
      id
      firstName
      lastName
      company {
        id
        name
        company_image
      }
    }
    payments {
      id
      type
      amount
      status
      original_amount
    }
    order {
      id
      order_date
      checkout_currency
      comment
    }
    customer_comment
    comment
    wdcComment
    latest_shipment {
      carrier
      tracking_code
    }
    shipments {
      ... {
        tracking_code
      }
    }
    customer_price
    customer_discount
    status
    deliveryDeadline
    deliveryDate
    promisedDeliveryDate
    expectedDeliveryDate
    expectedShippingDate
    return_option
    customerReturnDeadlineDate
    shippingDate
    expectedCollectionDate
    collectedDate
    transitDate
    endCustomerName
    customer_order_number
    order_number
    service_fee
    insurance_cost
    exchange_rate
    canada_mark_required
    dtl_required
    ProductType
    is_cfm
    cfm_final_price
    cfm_currency
    customer_currency_price
  }
  ${OrderDiamondFragment}
  ${MeleeFragment}
  ${GemstoneFragment}
`;

export const SupplierOrderItemFragment = gql`
  fragment SupplierOrderItem on OrderItem {
    id
    FbnId
    SupplierRaisedIssue {
      id
      orderItemId
      sellerId
      issueType
      reason
      isConfirmed
    }
    Product {
      ... on Diamond {
        id
        price
        rapaportDiscount
        # image
        brown
        green
        milky
        eyeClean
        availability
        # Moved  to  certificatee
        # v360 {
        #   top_index
        #   url
        #   frame_count
        # }
        supplier {
          id
          name
          company_settings {
            CompanyId
            self_serve_po
          }
        }
        supplierStockId
        certificate {
          ...CertificateInfo
        }
        location {
          id
          name
          country
        }
        offer {
          id
          discount
          price
          product_discount
          product_price
          original_offer {
            id
            discount
          }
          fbn_details {
            id
            internal_status
            inventory_type
          }
        }
      }
      ... on Melee {
        ...MeleeFragment
      }
      ... on Gemstone {
        ...GemstoneFragment
      }
    }
    carats
    pieces
    ProductType
    destination {
      id
      name
      country
    }
    buyer {
      id
      firstName
      company {
        id
        name
        company_image
      }
    }
    payments {
      id
      type
      amount
      status
      original_amount
      created_at
    }
    order {
      id
      order_date
      checkout_currency
      comment
    }
    comment
    wdcComment
    rupee_rate
    origin_country
    status
    self_confirm_status
    deliveryDeadline
    deliveryDate
    expectedDeliveryDate
    expectedPayoutDate
    expectedShippingDate
    shippingDate
    expectedCollectionDate
    collectedDate
    transitDate
    endCustomerName
    customer_order_number
    order_number
    service_fee
    insurance_cost
    exchange_rate
    buy_price
    buy_discount
  }
  ${CertificateInfoFragment}
  ${MeleeFragment}
  ${GemstoneFragment}
`;

export const AdminOrderItemCShipmentFragment = gql`
  fragment AdminOrderItemCShipmentFragment on OrderItem {
    id
    order {
      id
    }
    origin {
      id
      name
      country
      __typename
    }
    destination {
      id
      address1
      address2
      postalCode
      state
      city
      name
      country
    }
    return_option
    origin_country
    order_number
    ProductType
    Product {
      ... on Diamond {
        ...DiamondFragment
        diamondSupplierStockId
        certificate {
          ...CertificateInfo
        }
        diamondSupplierStockId
      }
      ... on Melee {
        ...MeleeFragment
        child_melee_collections {
          ...MeleeFragment
          carats
          supplierStockId
          total_price
          price_per_carat
          IsParentMeleeCollection
        }
        #        supplierStockId
        total_price
        price_per_carat
        IsParentMeleeCollection
      }
      ... on Gemstone {
        ...GemstoneFragment
        gemstoneSupplierStockId
      }
      ... on Jewellery {
        ...JewelleryFragment
        supplier {
          id
          name
        }
        mounts {
          ...JewelleryMountFragment
        }
        stones {
          ...JewelleryStoneFragment
        }
        jewellery_files {
          ...JewelleryFileFragment
        }
      }
    }
    deliveryDeadline
    buyer {
      id
      company {
        id
        name
        company_settings {
          CompanyId
          company_code
          customer_order_no_on_bc
        }
      }
    }
    seller {
      company {
        name
      }
    }
    base_sell_price
    buy_price
    buy_discount
    nivoda_markup
    return_option

    shipments {
      id
      envelope_number
      fbn_shipment
    }
    parent_order_item {
      id
      order_number
    }
  }
  ${DiamondFragment}
  ${CertificateInfoFragment}
  ${MeleeFragment}
  ${GemstoneFragment}
  ${JewelleryFragment}
  ${JewelleryMountFragment}
  ${JewelleryStoneFragment}
  ${JewelleryFileFragment}
`;

export const AdminFullOrderItemFragment = gql`
  fragment AdminFullOrderItem on OrderItem {
    ...AdminOrderItem
    buyer {
      id
      company {
        id
        company_settings {
          CompanyId
          company_code
          customer_order_no_on_bc
        }
        ip_active
        default_credit_enabled
        cleared_for_credit
        advance_payment
        credit_default_days
        credit_limit
        credit_consumed
        market_pay_limit
        market_pay_limit_consumed
        account_limit
        account_limit_consumed
        default_checkout_option
        billing_currency_preference
        market_pay_active
        overshot_limit
      }
    }
    seller {
      company {
        name
      }
    }
    shipping_reference_number
    customer_order_number
    customer_comment
    delay_comment
    not_available_reason
    cancelled_reason {
      id
      reason
    }
    base_sell_price
    base_sell_discount
    buy_price
    buy_discount
    nivoda_markup
    return_option
    return_percentage_charge
    updatedAt
    part_of_gia_request
    request_source
    mp_processing_status
    checkout_option_selected
    assigned_to {
      id
      firstName
      lastName
    }
    orderToConfirmDelay {
      reason_type
      text
    }
    confirmToCollectDelay {
      reason_type
      text
    }
    collectToShipDelay {
      reason_type
      text
    }
    FbnType
    supplier_invoice_country
    all_in_pricing
    shippedToDeliveredDelay {
      reason_type
      text
    }

    notes {
      ...AdminNote
    }

    shipments {
      id
      envelope_number
      fbn_shipment
    }
    payments {
      id
      status
    }
    updates {
      id
      type
      value_name
      value_from
      value_to
      reverse
      action_taken
      createdAt
      user {
        id
        firstName
        lastName
      }
    }
    parent_order_item {
      id
      order_number
    }
    issues {
      id
      OrderItemId
      reason {
        id
        type
        text
      }
      order_item_status
      createdAt
    }
  }
  ${AdminOrderItemFragment}
  ${NoteFragment}
`;

export const AdminOrderLongInfoFragment = gql`
  fragment AdminOrderLongInfo on OrderItem {
    buy_price_confirmed
    origin_country
    origin_city
    checkout_currency
    exchange_rate
    transit_country
    markup_override
    rupee_rate
    mpf_charge
    credit_charge
    credit_duration
    invoice {
      invoice_number
      id
      invoice_sent
      invoiced_at
    }
    supplier_paid
    supplier_invoice {
      id
      invoice_number
      invoiced_at
      other_charges
      shipping_charges
    }
    shipments {
      id
      tracking_code
      shipment_method
      carrier
      status
      service_type
      shipment_created
      shipment_delivered
      nivoda_shipment
      transit_shipment
      package_slip
      label
      label_downloaded
      commercial_invoice
      commercial_invoice_lg
      origin {
        id
        country
      }
      destination {
        id
        country
      }
      parent_shipment {
        id
        tracking_code
        shipment_method
        status
        carrier
        shipment_created
        shipment_delivered
        transit_shipment
        nivoda_shipment
        package_slip
        label
        label_downloaded
        origin {
          id
          country
        }
        destination {
          id
          country
        }
      }
    }
    payments {
      type
      amount
      created_at
      amount
    }
    ...LOSFragment
  }
  ${LOSFragment}
`;

export const AdminOrderInfoFragment = gql`
  fragment AdminOrderInfo on Order {
    id
    order_date
    user_comment
    checkout_currency
    items {
      ...AdminFullOrderItem
      ...AdminOrderLongInfo
    }
  }
  ${AdminFullOrderItemFragment}
  ${AdminOrderLongInfoFragment}
`;

export const MELEE_ORDER_OVERVIEW_FRAGMENT = gql`
  fragment MeleeOrderOverviewFragment on OrderItem {
    id
    carats
    pieces
    Product {
      ... on Melee {
        ...MeleeDescription
        original_carats_ordered
        child_melee_collections {
          id
          carats
          original_carats_ordered
          cost_price_per_carat
          total_cost_price
          supplierStockId
          supplier {
            id
            name
          }
        }
        supplier {
          id
          name
        }
        cost_price_per_carat
        total_cost_price
      }
    }
  }
  ${MeleeDescriptionFragment}
`;

export const AdminOrderItemSbsCShipmentFragment = gql`
  fragment AdminOrderItemSbsCShipmentFragment on OrderItem {
    id
    order {
      id
    }
    origin {
      id
      name
      country
      __typename
    }
    destination {
      id
      address1
      address2
      postalCode
      state
      city
      name
      country
    }
    return_option
    origin_country
    order_number
    ProductType
    Product {
      ... on Diamond {
        id
        price
        rapaportDiscount
        supplierStockId
        origin_country
        diamondSupplierStockId
        certificate {
          id
          certNumber
          cut
          shape
          carats
          color
          clarity
          floCol
          floInt
          lab
          polish
          colorShade
          symmetry
          labgrown
          f_color
          f_overtone
          f_intensity
          verified
        }
        diamondSupplierStockId
      }
      ... on Melee {
        id
        pieces
        carats
        supplierStockId
        total_price
        price_per_carat
        IsParentMeleeCollection
        MeleeCertificate: certificate {
          id
          certNumber
          shape
          carats
          color
          cut
          cut_type
          polish
          symmetry
          clarity
          lab
          labgrown
          floInt
          floCol
          f_color
          f_intensity
          f_overtone
        }
        child_melee_collections {
          id
          pieces
          carats
          supplierStockId
          total_price
          price_per_carat
          MeleeCertificate: certificate {
            id
            certNumber
            shape
            carats
            color
            cut
            cut_type
            polish
            symmetry
            clarity
            lab
            labgrown
            floInt
            floCol
            f_color
            f_intensity
            f_overtone
          }
        }
      }
      ... on Gemstone {
        id
        supplierStockId
        gemstoneSupplierStockId
        availability
        mine_of_origin
        gemstoneSupplierStockId
        price
        final_price
        GemstoneCertificate: certificate {
          id
          certNumber
          lab
          gemType
          cut
          colorShade
          color
          carats
          shape
          clarity
        }
      }
      ... on Jewellery {
        id
        sku
        status
        jewellery_type
        collection_name_supplier
        mounts {
          id
          status
          ProductType
        }
        stones {
          id
          status
          stone_position
          ProductType
        }
      }
    }
    deliveryDeadline
    buyer {
      id
      company {
        id
        name
        company_settings {
          CompanyId
          company_code
          customer_order_no_on_bc
        }
      }
    }
    seller {
      company {
        name
      }
    }
    base_sell_price
    buy_price
    buy_discount
    nivoda_markup
    return_option
    parent_order_item {
      id
      order_number
    }
  }
`;
