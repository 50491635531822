import { gql } from '@apollo/client';
import { CFMFragments } from 'graphql/fragments/CfmFragments';

import { AdminGraphqlQuery } from '../../../graphql-admin';
import {
  AdminDiamondFragment,
  AdminDiamondPriceFragment,
  CompanySettingsFragment,
  GemstoneCertificate,
  MeleeFragment,
} from '../../fragments';
import { GraphqlFactory, GraphqlMutation } from '../../factory';
import { omit } from 'lodash';
import { MeleeFragmentForStockIdSearch } from 'graphql/fragments/MeleeFragment';
import { GemstoneFragmentForStockIdSearch } from 'graphql/fragments/GemstoneOfferFragment';

export let process_gemstone_data = (key, data) => {
  let items = (data && data[key] != null && data[key]?.items) || [];
  let rest = data && data[key];
  let GemstoneItems = items?.filter((item) => item.ProductType === 'Gemstone');
  let itemsWithoutGemstones = items?.filter(
    (item) => item.ProductType !== 'Gemstone'
  );
  let transformGemstoneItems = GemstoneItems.map((order) => {
    return {
      ...order,
      Product: {
        ...order.Product,
        certificate: order?.Product?.GemstoneCertificate,
      },
    };
  });
  let all_items = [...transformGemstoneItems, ...itemsWithoutGemstones];
  return {
    ...data,
    [key]: {
      ...rest,
      items: all_items,
    },
  };
};

export const GetSupplier = AdminGraphqlQuery({
  query: gql`
    query ($q: String!, $type: String) {
      search_by_company(q: $q, type: $type) {
        items {
          name
          type
          id
        }
      }
    }
  `,
});

export const GetSupplierCompanyForMerge = AdminGraphqlQuery({
  query: gql`
    query ($q: String!, $type: String) {
      search_by_company(q: $q, type: $type) {
        items {
          id
          name
          company_image
          email
          address1
          city
          postalCode
          country
        }
      }
    }
  `,
});

export const GetUsersByCompanyID = AdminGraphqlQuery({
  query: gql`
    query ($id: ID!) {
      company(id: $id) {
        id
        company_users {
          id
          email
          firstName
          lastName
          phone1
          profile_image
          company {
            id
            name
            company_image
            email
            address1
            city
            postalCode
            country
          }
        }
      }
    }
  `,
});
// admin query to get all companies
export let AdminAllCompanies = AdminGraphqlQuery({
  query: gql`
    query ($limit: Int) {
      all_companies(limit: $limit) {
        items {
          id
          name
          country
          type
        }
      }
    }
  `,
});

export const GetLegalNamesByCompanyId = AdminGraphqlQuery({
  query: gql`
    query ($id: ID!) {
      company(id: $id) {
        id
        legal_names
      }
    }
  `,
});

export const GetVATNumbersByCompanyId = AdminGraphqlQuery({
  query: gql`
    query ($id: ID!) {
      company(id: $id) {
        id
        vat_numbers
      }
    }
  `,
});

export const GET_LEGAL_ENTITIES_BY_COMPANY_ID = gql`
  query getGetLegalEntitiesByCompanyId($id: ID!) {
    company(id: $id) {
      id
      legal_entities {
        id
        legal_name
        vat_number
        invoice_currency
        country
        bank_details {
          name
          branchAddress
          benificiaryName
          benificiaryAddress
          currency
          ABARoutingACH
          routingWires
          accountNumber
          swiftBIC
          IBAN
          sortCode
          IFSC
          BSB
          linkedEntity
        }
      }
    }
  }
`;

// admin query to get company by id
export let AdminCompanyById = AdminGraphqlQuery({
  query: gql`
    query getAdminSingleCompanyDetails($id: ID!) {
      company(id: $id) {
        id
        legal_names
        country
        name
        registration_number
        duns_number
        allianz_id
        company_type
        address1
        type
        main_location
        apiFileName
        api_type
        rapnet_id
        website
        free_shipments_left
        average_monthly_purchase
        rings_sold_monthly
        onboarding_date
        status
        verified_at
        company_owner {
          id
        }
        risk_collection {
          id
          companyId
          collectionJudgement
          collectionComment
          accountFlag
          flaggingReason
          flaggedDate
          createdAt
          updatedAt
        }
        risk_collection_history {
          id
          company {
            name
          }
          collectionJudgement
          collectionComment
          accountFlag
          flaggingReason
          flaggedDate
          createdAt
          updatedAt
          updated_by {
            firstName
            lastName
          }
        }
        office_closure_recurring_days
        supplier_status
        rjc
        nivoda_express_enabled
        type_detail
        company_settings {
          ...CompanySettingsFragment
          updates {
            id
            type
            value_name
            value_from
            value_to
            reverse
            action_taken
            createdAt
            user {
              id
              firstName
              lastName
            }
          }
        }

        locations {
          id
          name
          address1
          country
          address2
          postalCode
          state
          city
          suburb
          location_code
          kyc_verified
        }
        feed_settings
        payment_terms {
          id
          payment_term_type
          payment_term_days
          payment_term_interval
          payment_term_pay_day
          supplier_credit_limit_usd
          location
        }

        company_users {
          id
          firstName
          lastName
          email
          phone1
          is_primary_contact
          company_role
          country
          steps_required
          default_location {
            location {
              id
              name
              country
            }
          }
          cart_items {
            offer {
              id
            }
          }
          shortlist_items {
            offer {
              id
            }
          }
          company {
            id
          }
          holds_count
        }
        billing_emails
        return_charge
        auto_invoice
        incentive_pay_active
        invoice_day
        billing_currency_preference
        qc_pref
        product_tour_had
        bank_fee_charge
        sow
        markup_per_order
        lg_diamond_enabled
        natural_diamond_enabled
        invoice_per_shipment
        nivoda_fee_grouped
        nivoda_fee_in_diamond
        ip_charge_upfront
        ip_reminders_active
        ip_early_payment_refund
        ip_grace_period
        ip_upfront_charge_percentage
        ip_percentage_charge
        ip_late_payment_percentage_charge
        order_notification_emails
        dummy_account
        sales_person {
          id
          firstName
          lastName
        }
        account_manager {
          id
          firstName
          lastName
        }
        fraudulent_customer {
          restriction_date
          restriction_reason
          restricted_by {
            firstName
            lastName
          }
        }
        mpf_charged
        min_fee_per_stone_amount
        include_min_fee_in_per
        single_supplier_cutoff
        single_supplier_cutoff_amount
        single_supplier_cutoff_charge
        markup_cap_amount
        invoice_last_leg
        advance_payment
        supplier_agreement_signed
        data_verified
        is_msme
        bdb_code
        concierge_charge_included
        concierge_charge_percentage
        sales_channel
        loupe360_key
        credit_limit
        credit_consumed
        credit_safe_limit
        cleared_for_credit
        cr_30_per
        cr_60_per
        cr_90_per
        credit_default_days
        default_credit_enabled
        credit_grace_period
        credit_late_payment_percentage_charge
        market_pay_limit
        market_pay_limit_consumed
        total_open_non_mf_invoice
        market_pay_active
        market_pay_eligible
        days_allowed_after_duedate
        disable_checkout
        credit_report {
          creditsafe_company_name
          SafeNumber
          current_credit_rating
          current_contract_limit
          current_credit_score
          pdf_url
        }
        vat_registered
        vat_number
        article_42_vat_exemption
        ip_active
        account_limit_consumed
        account_limit
        insurance_limit
        memo_return_penalty
        insurance_currency
        diamond_registered
        last_upload_success
        createdAt
        country_of_incorporation
        all_in_pricing
        default_checkout_option
        charge_last_mile_fee
        markup_diamond_labgrown
        markup_diamond_natural
        markup_gemstone
        markup_melee
        closure_dates {
          id
          start_date
          end_date
          company_id
        }
        shipment_mails
        overshot_limit
        min_fee_per_lg_stone_amount
        lg_percent_charged
        natural_percent_charged
        qc_mails
        market_pay_rejection_reason
        market_pay_limit_status
        inventory_type
        is_kyc_verified
        bank_details {
          id
          IBAN
          name
          branchAddress
          country
          isApproved
          benificiaryName
          benificiaryAddress
          ABARoutingACH
          routingWires
          accountNumber
          branchCode
          accountType
          taxID
          swiftBIC
          sortCode
          IFSC
          linkedEntity
          bank_details_proof
          bank_details_proof_key
          approvals {
            modelName
            reference_id
            edited_by
            editedAt
            status
            approval_details {
              approved_by
              timestamp
            }
          }
          pending_details {
            id
            name
            branchAddress
            benificiaryName
            benificiaryAddress
            currency
            ABARoutingACH
            routingWires
            accountNumber
            branchCode
            branchName
            accountType
            taxID
            swiftBIC
            IBAN
            sortCode
            IFSC
            BSB
            bank_details_proof
          }
        }
        credit_note_application
        supplier_auto_shipment_creation
        supplier_shipment_default_address
      }
    }
    ${CompanySettingsFragment}
  `,
});
export let COMPANY_ID = gql`
  query AdminCompanyOnly($id: ID!) {
    company(id: $id) {
      id
      name
    }
  }
`;

export let AdminCompanyByIdCFMSettings = AdminGraphqlQuery({
  query: gql`
    query AdminCompanyByIdCFMSettings($id: ID!) {
      company(id: $id) {
        id
        name
        loupe360_url
        cfm_settings {
          ...CFMFragments
        }
      }
    }
    ${CFMFragments}
  `,
});

export const SEARCH_CUSTOMER_COMPANY = gql`
  query searchCustomerCompany($q: String!, $type: String!) {
    search_by_company(q: $q, type: $type) {
      items {
        id
        name
        company_users {
          id
          firstName
          lastName
        }
        OwnerId
        main_location
        company_settings {
          CompanyId
          holds_enabled
          accept_returns
          action_other_user_hold
          memo_enabled
        }
        locations {
          id
          name
          city
          country
          default_shipping_address
          last_mile_delivery_fee {
            id
            country_name
            country_code
            last_mile_fee
            local_fee
          }
        }
        charge_last_mile_fee
        default_credit_enabled
        ip_active
        default_checkout_option
        all_in_pricing
      }
    }
  }
`;

export let SearchByKey = AdminGraphqlQuery({
  query: SEARCH_CUSTOMER_COMPANY,
});

export const SEARCH_BY_KEY = gql`
  query ($q: String!, $type: String!) {
    search_by_company(q: $q, type: $type) {
      items {
        id
        name
        company_users {
          id
          firstName
          lastName
        }
        OwnerId
        main_location
        company_settings {
          CompanyId
          holds_enabled
          accept_returns
        }
        locations {
          id
          name
          city
          country
        }
        default_credit_enabled
        ip_active
        default_checkout_option
        cr_30_per
        cr_60_per
        cr_90_per
        all_in_pricing
      }
    }
  }
`;

export let SEARCH_BY_COMPANY = gql`
  query searchByCompany($q: String!, $type: String!) {
    search_by_company(q: $q, type: $type) {
      items {
        id
        name
        company_users {
          id
          firstName
          lastName
        }
        OwnerId
        main_location
        company_settings {
          CompanyId
          holds_enabled
          accept_returns
        }
        locations {
          id
          name
          city
          country
        }
        default_credit_enabled
        ip_active
        default_checkout_option
      }
    }
  }
`;

export const SearchByCompanyName = AdminGraphqlQuery({
  query: gql`
    query ($q: String!, $type: String!) {
      search_by_company(q: $q, type: $type) {
        items {
          id
          name
          locations {
            id
          }
        }
      }
    }
  `,
});

const diamonds_fragment = gql`
  fragment Diamond_Stock_Info on Diamond {
    id
    supplierStockId
    certificate {
      id
      certNumber
      lab
      carats
    }
    supplier {
      id
      name
      company_settings {
        CompanyId
        accept_returns
      }
    }
    location {
      id
    }
    price
  }
`;

const meleeCollection_fragment = gql`
  fragment MeleeCollection_Stock_Info on Melee {
    id
    supplierStockId
    carats
    pieces
    supplier {
      id
      name
      company_settings {
        CompanyId
        accept_returns
      }
    }
    location {
      id
    }
    price_per_carat
  }
`;

export let SearchCertificatesByStockId = gql`
  query getSearchByStockId($q: String!, $type: [ProductType!]!) {
    search_by_stockId(q: $q, type: $type) {
      total_count
      items {
        Product {
          ...AdminDiamond
          ...AdminDiamondPrice
          ...GemstoneFragmentForStockIdSearch
          ...MeleeFragmentForStockIdSearch
        }
        ProductType
      }
    }
  }
  ${AdminDiamondFragment}
  ${AdminDiamondPriceFragment}
  ${GemstoneFragmentForStockIdSearch}
  ${MeleeFragmentForStockIdSearch}
`;

export const SEARCH_BY_STOCK_ID = gql`
  query searchByStockID($q: String!, $type: ProductType!) {
    search_by_stockId(q: $q, type: $type) {
      items {
        ...Diamond_Stock_Info
        ...MeleeCollection_Stock_Info
      }
    }
  }
  ${diamonds_fragment}
  ${meleeCollection_fragment}
`;

export const FETCH_COMPANY_ORDERS = gql`
  query getAllCustomerOrdersByCompanyID(
    $id: ID!
    $query: AdminAllOrdersQuery
    $offset: Int
  ) {
    all_order_items_by_company_id(
      id: $id
      offset: $offset
      limit: 48
      query: $query
    ) {
      type
      total_count
      items {
        id
        order_number
        FbnType
        Product {
          ... on Diamond {
            id
            supplierStockId
            brown
            green
            blue
            gray
            milky
            other
            eyeClean
            certificate {
              id
              lab
              certNumber
              shape
              carats
              color
              cut
              polish
              symmetry
              clarity
              floCol
              floInt
              labgrown
            }
            supplier {
              id
              name
            }
          }
          ... on Melee {
            ...MeleeFragment
            supplierStockId
          }
          ... on Gemstone {
            id
            supplierStockId

            GemstoneCertificate: certificate {
              id
              ...GemstoneCertificate
            }
            supplier {
              id
              name
            }
          }
        }
        ProductType
        customer_order_number
        carats
        pieces
        order {
          id
          order_date
        }
        status
        expectedCollectionDate
        expectedDeliveryDate
        deliveryDate
        base_sell_price
        nivoda_markup
        return_option
        return_percentage_charge
        invoice {
          invoice_link
          id
          invoice_number
        }
        supplier_invoice {
          invoice_link
          id
          invoice_number
        }
        buyer {
          id
          company {
            id
            name
          }
        }
      }
    }
  }
  ${MeleeFragment}
  ${GemstoneCertificate}
`;

// admin query to fetch all orders for company
export const FetchCompanyOrders = AdminGraphqlQuery({
  query: gql`
    query getAllCustomerOrdersByCompanyID(
      $id: ID!
      $query: AdminAllOrdersQuery
      $offset: Int
    ) {
      all_order_items_by_company_id(
        id: $id
        offset: $offset
        limit: 48
        query: $query
      ) {
        type
        total_count
        items {
          id
          order_number
          FbnType
          Product {
            ... on Diamond {
              id
              supplierStockId
              brown
              green
              blue
              gray
              milky
              other
              eyeClean
              certificate {
                id
                lab
                certNumber
                shape
                carats
                color
                cut
                polish
                symmetry
                clarity
                floCol
                floInt
                labgrown
              }
              supplier {
                id
                name
              }
            }
            ... on Melee {
              ...MeleeFragment
              supplierStockId
            }
            ... on Gemstone {
              id
              supplierStockId

              GemstoneCertificate: certificate {
                id
                ...GemstoneCertificate
              }
              supplier {
                id
                name
              }
            }
          }
          ProductType
          customer_order_number
          carats
          pieces
          order {
            id
            order_date
          }
          status
          expectedCollectionDate
          expectedDeliveryDate
          deliveryDate
          base_sell_price
          nivoda_markup
          return_option
          return_percentage_charge
          invoice {
            invoice_link
            id
            invoice_number
          }
          supplier_invoice {
            invoice_link
            id
            invoice_number
          }
          buyer {
            id
            company {
              id
              name
            }
          }
        }
      }
    }
    ${MeleeFragment}
    ${GemstoneCertificate}
  `,
  map_data: (data) => {
    let key = 'all_order_items_by_company_id';
    return process_gemstone_data(key, data);
  },
});

export const GET_NIVODA_OFFICE_LOCATIONS = gql`
  query getAllNivodaLocations {
    all_nivoda_locations {
      id
      name
      country
    }
  }
`;

export const GET_ALL_NIVODA_OFFICE_LOCATIONS = gql`
  query getAllNivodaLocations {
    all_nivoda_locations {
      id
      name
      country
      city
      address1
      address2
      postalCode
      state
    }
  }
`;

export const GetAllCustomers = AdminGraphqlQuery({
  query: gql`
    query {
      get_all_customers {
        total_count
        items {
          id
          name
          country_of_incorporation
        }
      }
    }
  `,
});

export const SEARCH_SUPPLIER_COMPANY = gql`
  query searchSupplierCompany($q: String!, $type: String!) {
    search_by_company(q: $q, type: $type) {
      items {
        id
        name
        locations {
          id
          name
          city
          country
        }
      }
    }
  }
`;

export let company_fragment = gql`
  fragment Full_Company on Company {
    id
    name
    website
    about
    main_location
    company_image
    company_banner
    country_of_incorporation
    type_detail
    api_type
  }
`;

let location_fragment = gql`
  fragment Full_Location on Location {
    id
    name
    address1
    address2
    country
    city
    postalCode
  }
`;

export let GetUserProfile = GraphqlFactory({
  query: gql`
    query getUserProfile{
        me {
            id
            company {
                ...Full_Company
                locations {
                    ...Full_Location
                }
            }
            closure_dates {
              id
              company_id
              start_date
              end_date
            }
        }
    }${company_fragment}, ${location_fragment}
`,
  map_data: (data) => {
    let { me, refetch } = data;
    let closure_dates = me.closure_dates;

    let new_closure_dates = closure_dates.map((ele) => {
      return omit(ele, '__typename');
    });
    return {
      me: { ...me, closure_dates: new_closure_dates },
      refetch: refetch,
    };
  },
});

export const UpdateRiskAndCollectionSettings = gql`
  mutation updateRiskAndCollectionSettings(
    $companyId: ID!
    $collectionJudgement: String
    $collectionComment: String
    $accountFlag: String
    $flaggingReason: String
  ) {
    admin_upsert_risk_and_collection_settings(
      companyId: $companyId
      collectionJudgement: $collectionJudgement
      collectionComment: $collectionComment
      accountFlag: $accountFlag
      flaggingReason: $flaggingReason
    ) {
      id
    }
  }
`;

export const MarkCustomerFraudulent = gql`
  mutation markCustomerFraudulent(
    $company_id: ID!
    $restriction_reason: String!
  ) {
    admin_mark_customer_fraudulent(
      company_id: $company_id
      restriction_reason: $restriction_reason
    ) {
      id
    }
  }
`;

export let UpdateCompany = GraphqlMutation({
  query: gql`
    mutation UpdateCompany(
      $main_location: ID!
      $website: String!
      $about: String!
      $office_closure_dates: [OfficeClosureDate]
      $deleted_date_ids: [String]
    ) {
      update_company(
        main_location: $main_location
        website: $website
        about: $about
        office_closure_dates: $office_closure_dates
        deleted_date_ids: $deleted_date_ids
      ) {
        ...Full_Company
      }
    }
    ${company_fragment}
  `,
});

export const GET_NIVODA_COMPANY_DETAILS = gql`
  query getNivodaCompany($id: ID!) {
    company(id: $id) {
      id
      name
      company_users {
        id
        firstName
        lastName
      }
      OwnerId
      main_location
      company_settings {
        CompanyId
        holds_enabled
        accept_returns
        action_other_user_hold
      }
      locations {
        id
        name
        city
        country
        default_shipping_address
        last_mile_delivery_fee {
          id
          country_name
          country_code
          last_mile_fee
          local_fee
        }
      }
      charge_last_mile_fee
      default_credit_enabled
      ip_active
      default_checkout_option
      all_in_pricing
    }
  }
`;

export const GET_LOCATION_PAYMENT_BY_COMPANY = gql`
  query getLocPayment($company_id: ID!, $user_id: ID!, $cart_price: Float) {
    admin_get_customer_details_for_order_request(
      company_id: $company_id
      user_id: $user_id
      cart_price: $cart_price
    ) {
      delivery_locations {
        id
        address1
        address2
        city
        name
        state
        country
        default_shipping_address
        postalCode
      }
      payment_terms {
        api_name
        default_selected
        disable_selection
        interest_rate
        isEnabled
        subTitle
        sub_total
        title
        translationSubTitleKey
        translationTitleKey
      }
    }
  }
`;
