import { AdminGraphqlQuery } from '../../../graphql-admin';
import { gql } from '@apollo/client';
import {
  AdminFullOrderItemFragment,
  AdminOrderLongInfoFragment,
  CertificateLongInfoFragment,
  DiamondWithCertFragment,
  NivodaExpressItemFragment,
  LOSFragment,
  MeleeFragment,
  NoteFragment,
  CertificateInfoFragment,
  GemstoneCertificate,
  MELEE_ORDER_OVERVIEW_FRAGMENT,
} from '../../fragments';
import { BaseCertificateFragment } from 'graphql/fragments/CertificateFragment';

export let process_gemstone_data = (key, data) => {
  let items = (data[key] != null && data[key]?.items) || [];
  let GemstoneItems = items?.filter((item) => item.ProductType === 'Gemstone');
  let itemsWithoutGemstones = items?.filter(
    (item) => item.ProductType !== 'Gemstone'
  );
  let transformGemstoneItems = GemstoneItems.map((order) => {
    return {
      ...order,
      Product: {
        ...order.Product,
        certificate: order?.Product?.GemstoneCertificate,
      },
    };
  });
  let all_items = [...transformGemstoneItems, ...itemsWithoutGemstones];

  return {
    ...data,
    [key]: {
      ...data[key],
      items: all_items,
    },
  };
};

// noinspection GraphQLUnresolvedReference
export const GetAllLiveOrders = AdminGraphqlQuery({
  query: gql`
    query ($offset: Int) {
      all_orders: all_order_items(limit: 50, offset: $offset)
        @connection(key: "all_order_items") {
        total_count
        items {
          base_sell_price
          base_sell_discount
          buy_price
          buy_discount
          nivoda_markup
          buy_price_confirmed
          origin_country
          checkout_currency
          Product {
            ... on Diamond {
              ...DiamondWithCert
            }
          }
          ...LOSFragment

          assigned_to {
            id
            firstName
            lastName
            profile_image
          }
        }
      }
    }
    ${DiamondWithCertFragment}
    ${LOSFragment}
  `,
  map_data: ({ all_orders, ...props }) => {
    return {
      ...props,
      all_orders: all_orders && all_orders.items,
      total_count: all_orders ? all_orders.total_count : 0,
      has_more:
        all_orders && all_orders.items.length !== all_orders.total_count,
    };
  },
});

export const GetRTTOrders = AdminGraphqlQuery({
  query: gql`
    query getOrderItemsForRTT(
      $status_key: OrderItemStatusKey!
      $query: AdminOrderItemQuery!
      $origin_country: OriginCountry
    ) {
      orders_by_status(
        key: $status_key
        limit: 2000
        query: $query
        origin_country: $origin_country
      ) {
        total_count
        items {
          id
          buyer {
            id
            firstName
            company {
              id
              name
              company_settings {
                CompanyId
                company_code
                customer_order_no_on_bc
              }
              qc_pref
            }
          }
          parcel_paper
          buy_price
          buy_discount
          buy_price_confirmed
          customer_order_number
          collectedDate
          destination {
            id
            country
            location_code
          }
          FbnId
          FbnType
          FbnItem {
            ...NivodaExpressItemFragment
          }

          order {
            id
          }
          origin_country
          origin_city
          order_number

          Product {
            ... on Diamond {
              id
              availability
              supplierStockId
              diamondSupplierStockId
              certificate {
                ...BaseCertificate
              }

              supplier {
                id
                name
                country
                company_settings {
                  CompanyId
                  final_price
                }
              }
            }
            ... on Melee {
              ...MeleeFragment
              child_melee_collections {
                ...MeleeFragment
                supplierStockId
                total_price
                price_per_carat
                IsParentMeleeCollection
              }
              total_price
              price_per_carat
              IsParentMeleeCollection
            }
            ... on Gemstone {
              id
              availability
              GemstoneCertificate: certificate {
                id
                ...GemstoneCertificate
              }
              gemstoneSupplierStockId
              supplier {
                id
                name
                country
                company_settings {
                  CompanyId
                  final_price
                }
              }
              supplierStockId
            }
          }
          ProductType
          self_confirm_status
          shipping_reference_number
          status
        }
      }
    }
    ${BaseCertificateFragment}
    ${NivodaExpressItemFragment}
    ${MeleeFragment}
    ${GemstoneCertificate}
  `,
  map_data: (data) => {
    let key = 'orders_by_status';
    return process_gemstone_data(key, data);
  },
});

export let PODelayReasonGroups = [
  'Supplier',
  'QC',
  'Customer',
  'Internal',
  'Terms',
  'Price',
  'Other',
];
export const GET_PO_DELAY_REASONS = gql`
  query {
    admin_get_all_PO_delay_reasons {
      id
      text
      type
      group
    }
  }
`;
export let GetPODelayReasons = AdminGraphqlQuery({
  query: GET_PO_DELAY_REASONS,
});

export const OrdersByKeyBasic = AdminGraphqlQuery({
  query: gql`
    query getOrdersForPOAndOthers(
      $status_key: OrderItemStatusKey!
      $query: AdminOrderItemQuery!
      $origin_country: OriginCountry
    ) {
      orders_by_status(
        key: $status_key
        limit: 2000
        query: $query
        origin_country: $origin_country
      ) {
        total_count
        items {
          id
          order {
            id
            user_comment
            order_date
          }
          SupplierRaisedIssue {
            id
            reason
            issueType
            orderItemId
            isConfirmed
          }
          seller {
            id
            firstName
            country
            company {
              id
              name
              qc_pref
              country_of_incorporation
              locations {
                id
                name
                country
                address1
                address2
                postalCode
                city
              }
              company_settings {
                CompanyId
                company_code
                customer_order_no_on_bc
              }
            }
          }
          csp
          status
          order_number
          base_sell_price
          base_sell_discount
          origin_country
          origin_city
          buy_price_confirmed
          nivoda_markup
          transit_country
          promisedDeliveryDate
          buy_price
          buy_discount
          canada_mark_required
          dtl_required
          confirmed_by_supplier
          deliveryDeadline
          deliveryDate
          expectedDeliveryDate
          shippingDate
          expectedShippingDate
          expectedCollectionDate
          collectedDate
          transitDate
          wdcComment
          comment
          customer_order_number
          customer_comment
          supplier_paid
          return_option
          return_percentage_charge
          rupee_rate
          waiting_for_customer
          part_of_gia_request
          part_of_first_purchase
          request_source
          updatedAt
          wfc_start
          wfc_end
          to_PO_time
          estim_confm_time
          destination {
            id
            name
            country
            address1
            address2
            postalCode
            city
            state
          }
          issues {
            id
            OrderItemId
            reason {
              id
              type
              text
            }
            order_item_status
            createdAt
          }
          self_confirm_status
          self_confirm_bgmec_value
          supplier_api_triggered
          FbnId
          FbnType
          destination {
            id
            country
          }
          shipments {
            id
            tracking_code
          }
          buyer {
            id
            firstName
            country
            company {
              id
              name
              country_of_incorporation
              qc_pref
              locations {
                id
                name
                country
                address1
                address2
                postalCode
                city
              }
              company_settings {
                CompanyId
                company_code
                customer_order_no_on_bc
              }
            }
          }
          invoice {
            id
            invoice_number
            type
            settled
          }
          supplier_invoice {
            id
          }
          los_supplier_paid {
            text
            status
          }
          Product {
            ... on Diamond {
              id
              supplierStockId
              diamondSupplierStockId
              bowtie
              brown
              green
              blue
              gray
              milky
              other
              eyeClean
              availability
              last_enquiry_answered
              has_bgmec_enquiry
              has_media_enquiry
              offer {
                id
                price
                discount
                price_per_carat
                product_price
                product_discount
                return_price
                return_discount
              }
              certificate {
                ...BaseCertificate
              }
              location {
                id
                name
                country
              }
              supplier {
                id
                name
                website
                country
                accept_returns
                supplier_auto_shipment_creation
                company_settings {
                  CompanyId
                  final_price
                  website_order_placement
                  hk_only_delivery
                  in_with_charges_delivery
                  communicates_through_email
                  communication_email
                  in_delivery_upto
                  in_delivery_upto_amount
                  self_serve_po
                  supplier_based_comment
                }
                payment_terms {
                  id
                  payment_term_type
                  location
                }
              }
            }
            ... on Melee {
              ...MeleeFragment
              child_melee_collections {
                ...MeleeFragment
                supplierStockId
                total_price
                price_per_carat
                IsParentMeleeCollection
              }
              total_price
              price_per_carat
              IsParentMeleeCollection
            }
            ... on Gemstone {
              id
              supplierStockId
              availability
              last_enquiry_answered
              has_bgmec_enquiry
              has_media_enquiry
              GemstoneCertificate: certificate {
                id
                ...GemstoneCertificate
              }
              gemstoneSupplierStockId
              location {
                id
                name
                country
              }
              supplier {
                id
                name
                website
                country
                accept_returns
                supplier_auto_shipment_creation
                company_settings {
                  CompanyId
                  final_price
                  website_order_placement
                  hk_only_delivery
                  in_with_charges_delivery
                  communicates_through_email
                  communication_email
                  in_delivery_upto
                  in_delivery_upto_amount
                  self_serve_po
                  supplier_based_comment
                }
                payment_terms {
                  id
                  payment_term_type
                  location
                }
              }
            }
          }
          ProductType
          assigned_to {
            id
            firstName
            lastName
            profile_image
          }
          notes {
            ...AdminNote
          }
          qc_customer_status
          Qc {
            id
            status
          }
        }
      }
    }
    ${BaseCertificateFragment}
    ${MeleeFragment}
    ${NoteFragment}
    ${GemstoneCertificate}
  `,
  map_data: (data) => {
    let key = 'orders_by_status';
    return process_gemstone_data(key, data);
  },
});

export const GetRTCOrders = AdminGraphqlQuery({
  query: gql`
    query getOrderItemsForRTC(
      $status_key: OrderItemStatusKey!
      $query: AdminOrderItemQuery!
      $origin_country: OriginCountry
      $isMobile: Boolean!
    ) {
      orders_by_status(
        key: $status_key
        limit: 2000
        query: $query
        origin_country: $origin_country
      ) {
        total_count
        items {
          id

          order {
            id
            user_comment
            order_date
          }

          order_number
          status
          origin_country
          origin_city
          buy_price_confirmed
          buy_price
          buy_discount
          customer_order_number
          confirmed_by_supplier
          expectedCollectionDate

          destination {
            id
            country
          }

          buyer {
            id
            firstName
            company {
              id
              name
              qc_pref
            }
          }

          FbnId
          FbnType
          status

          Product {
            ... on Diamond {
              id
              supplierStockId
              diamondSupplierStockId
              certificate {
                ...BaseCertificate
              }
              supplier {
                id
                name
                accept_returns
                country
                # payment_terms is required for RTC mobile view so will be fetched conditionally
                payment_terms @include(if: $isMobile) {
                  id
                  payment_term_type
                  location
                }
              }
            }
            ... on Melee {
              ...MeleeFragment
              child_melee_collections {
                ...MeleeFragment
                supplierStockId
                total_price
                price_per_carat
                IsParentMeleeCollection
              }
              total_price
              price_per_carat
              IsParentMeleeCollection
            }
            ... on Gemstone {
              id
              supplierStockId
              availability
              GemstoneCertificate: certificate {
                id
                ...GemstoneCertificate
              }
              gemstoneSupplierStockId
              supplier {
                id
                name
                country
              }
            }
          }
          ProductType
          self_confirm_status
          notes {
            ...AdminNote
          }
          issues {
            id
            OrderItemId
            reason {
              id
              type
              text
            }
            order_item_status
            createdAt
          }
          transit_country
        }
      }
    }
    ${BaseCertificateFragment}
    ${MeleeFragment}
    ${NoteFragment}
    ${GemstoneCertificate}
  `,
  map_data: (data) => {
    let key = 'orders_by_status';
    return process_gemstone_data(key, data);
  },
});

export const GET_MELEE_ORDER_BY_ID = gql`
  query getMeleeOrderByIdForAddNUpdate($id: ID!) {
    order_item_by_id(id: $id) {
      ...MeleeOrderOverviewFragment
    }
  }
  ${MELEE_ORDER_OVERVIEW_FRAGMENT}
`;

// admin query to get one order item by order item id
export const GetOrderItemById = AdminGraphqlQuery({
  query: gql`
    query ($id: ID!) {
      order_item_by_id(id: $id) {
        ...AdminFullOrderItem
        ...AdminOrderLongInfo
      }
    }
    ${AdminFullOrderItemFragment}
    ${AdminOrderLongInfoFragment}
  `,
});

export const GetRTQCOrders = AdminGraphqlQuery({
  query: gql`
    query getOrderItemsForQC(
      $status_key: OrderItemStatusKey!
      $query: AdminOrderItemQuery!
      $origin_country: OriginCountry
    ) {
      orders_by_status(
        key: $status_key
        limit: 2000
        query: $query
        origin_country: $origin_country
      ) {
        total_count
        items {
          id
          buyer {
            id
            firstName
            company {
              id
              name
              qc_pref
              company_settings {
                CompanyId
                company_code
              }
            }
          }
          buy_price_confirmed
          customer_order_number
          destination {
            id
            country
          }
          FbnId
          FbnType
          order {
            id
          }
          origin_country
          origin_city
          order_number
          collectedDate
          current_location {
            id
            state
            name
            city
          }
          Product {
            ... on Diamond {
              id
              supplierStockId
              diamondSupplierStockId
              supplier {
                id
                name
              }
              certificate {
                ...BaseCertificate
              }
            }
            ... on Melee {
              ...MeleeFragment
              child_melee_collections {
                ...MeleeFragment
                supplierStockId
                total_price
                price_per_carat
                IsParentMeleeCollection
              }
              total_price
              price_per_carat
              IsParentMeleeCollection
            }
            ... on Gemstone {
              id
              supplierStockId
              GemstoneCertificate: certificate {
                id
                ...GemstoneCertificate
              }
              gemstoneSupplierStockId
              supplier {
                id
                name
                website
                country
              }
            }
          }
          ProductType
          self_confirm_status
          notes {
            ...AdminNote
          }
          status
          Qc {
            id
            status
          }
        }
      }
    }
    ${BaseCertificateFragment}
    ${MeleeFragment}
    ${NoteFragment}
    ${GemstoneCertificate}
  `,
  map_data: (data) => {
    let key = 'orders_by_status';
    return process_gemstone_data(key, data);
  },
});

export const GET_QC_ORDER_ITEM = gql`
  query ($id: ID!, $OrderItemId: ID) {
    order(id: $id, OrderItemId: $OrderItemId) {
      id
      items {
        id
        buyer {
          id
          company {
            id
            name
            qc_pref
            company_settings {
              CompanyId
              company_code
            }
          }
        }
        collectedDate
        buy_price_confirmed
        customer_order_number
        customer_comment
        destination {
          id
          name
          address1
          address2
          postalCode
          city
          country
        }
        FbnId
        FbnType
        order_number
        origin_country
        order {
          id
          user_comment
        }
        Product {
          ... on Diamond {
            id
            availability
            brown
            green
            other
            gray
            blue
            milky
            eyeClean
            bowtie
            has_bgmec_enquiry
            has_media_enquiry
            supplierStockId
            diamondSupplierStockId
            canada_mark_eligible
            supplier {
              id
              name
              country
              company_settings {
                CompanyId
                accept_qc_returns
                supplier_qc_comment
              }
            }
            certificate {
              ...CertificateLongInfo
            }
            last_qc {
              id
              status
              brown
              green
              milky
              eyeClean
              blue
              gray
              bowtie
              qc_date
              qc_completed_by {
                id
                firstName
                lastName
              }
              OrderItem {
                id
                order {
                  id
                }
              }
            }
          }
          ... on Gemstone {
            id
            availability
            has_media_enquiry
            supplierStockId
            supplier {
              id
              name
              country
            }
            GemstoneCertificate: certificate {
              id
              ...GemstoneCertificate
            }
          }
        }
        all_in_pricing
        QcGemstone {
          id
          gemType
          shape
          carats
          color
          colorShade
          clarity
          cut
          treatment
          mine_of_origin
          measurement
          lab
          productType
          durability
          notes
          status
          remarks
        }
        ProductType
        status
        qc_date
        qc_number
        Qc {
          id
          status
          notes
          brown
          green
          milky
          eyeClean
          blue
          gray
          bowtie
          mix_tinge_certificate
          surface_reaching_certificate
          inscription_certificate
          intended_natural
          chip
          cert
          QcCancelledReason {
            id
          }
          grouped_updates {
            __typename
            GroupedUpdateId
            createdAt
            user {
              id
              firstName
              lastName
              profile_image
              role
            }
            updates {
              id
              value_name
              value_from
              value_to
            }
            note {
              __typename
              id
              data
              createdAt
              updatedAt
            }
          }
        }
        notes {
          __typename
          id
          data
          status
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
            profile_image
            role
          }
        }
        qc_customer_status
        updates {
          id
          type
          value_name
          value_from
          value_to
          reverse
          action_taken
          createdAt
          user {
            id
            firstName
            lastName
            profile_image
            role
          }
        }
      }
    }
  }
  ${CertificateLongInfoFragment}
  ${GemstoneCertificate}
`;

export const GetAllQCCancelledReasons = AdminGraphqlQuery({
  query: gql`
    query {
      all_qc_cancelled_reasons {
        id
        reason
      }
    }
  `,
});

export const GET_ALL_GEMS_QC_CANCELLED_REASONS = gql`
  query {
    all_qc_cancelled_reasons_gemstones {
      id
      reason
    }
  }
`;

export const GetExpressRTCOrders = AdminGraphqlQuery({
  query: gql`
    query getExpressRTCOrders(
      $tray_number: Int
      $cert_number: String
      $origin_country: OriginCountry!
    ) {
      search_fbn_orders_by_tray_no_cert_no(
        tray_number: $tray_number
        cert_number: $cert_number
        origin_country: $origin_country
      ) {
        items {
          id
          destination {
            id
            name
            country
          }

          buyer {
            id
            firstName
            company {
              id
              name
              qc_pref
              country
              company_settings {
                CompanyId
                company_code
              }
            }
          }
          FbnType
          FbnItem {
            id
            tray_number
            cert_tray_number
            SpacecodeProductTag
            SpacecodeCertTag
          }
          Product {
            ... on Diamond {
              id
              supplierStockId
              diamondSupplierStockId
              bowtie
              brown
              green
              blue
              gray
              milky
              other
              eyeClean
              availability
              last_enquiry_answered
              has_bgmec_enquiry
              has_media_enquiry
              certificate {
                ...CertificateInfo
              }
              location {
                id
                name
                country
              }
              supplier {
                id
                name
                website
                country
                accept_returns
                company_settings {
                  CompanyId
                  final_price
                  website_order_placement
                  hk_only_delivery
                  in_with_charges_delivery
                  communicates_through_email
                  communication_email
                  in_delivery_upto
                  in_delivery_upto_amount
                  self_serve_po
                  supplier_based_comment
                }
                payment_terms {
                  id
                  payment_term_type
                  location
                }
              }
            }
            ... on Melee {
              ...MeleeFragment
              child_melee_collections {
                ...MeleeFragment
                supplierStockId
                total_price
                price_per_carat
                IsParentMeleeCollection
              }
              total_price
              price_per_carat
              IsParentMeleeCollection
            }
            ... on Gemstone {
              id
              supplierStockId
              availability
              last_enquiry_answered
              has_bgmec_enquiry
              has_media_enquiry
              GemstoneCertificate: certificate {
                id
                ...GemstoneCertificate
              }
              location {
                id
                name
                country
              }
              supplier {
                id
                name
                website
                country
                accept_returns
                company_settings {
                  CompanyId
                  final_price
                  website_order_placement
                  hk_only_delivery
                  in_with_charges_delivery
                  communicates_through_email
                  communication_email
                  in_delivery_upto
                  in_delivery_upto_amount
                  self_serve_po
                  supplier_based_comment
                }
                payment_terms {
                  id
                  payment_term_type
                  location
                }
              }
            }
          }
          ProductType
          order {
            id
            user_comment
            order_date
          }
          order_number
          origin_country
        }
        total_count
      }
    }
    ${CertificateInfoFragment}
    ${MeleeFragment}
    ${GemstoneCertificate}
  `,
});

export const GetExpressRTCConsumedTrays = AdminGraphqlQuery({
  query: gql`
    query getExpressRTCConsumedTrays($origin_country: OriginCountry!) {
      get_consumed_trays_with_stone_cert_number(
        origin_country: $origin_country
      ) {
        total_trays {
          trays_count
          stone_count
          cert_count
        }
        individual_trays {
          tray_number
          stone_count
          cert_count
        }
      }
    }
  `,
});
