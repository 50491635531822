import {
  holdExtensionStatusToTitle,
  holdsTabStatusToTitle,
  holdStatusToTitle,
} from './holds';
import { DiamondAvailability } from './product-availability';
import { capitalize } from 'lodash/string';
import { enquiryInternalStatusType } from './enquiry';
import { OrderItemStatus } from './order';
import { ShipmentStatus } from './shipment';

export const orderStatusTextMapper = {
  Added: 'Added',
  AWAITING_PAYMENT: 'Awaiting Payment',
  CANCELLED: 'Cancelled',
  CONFIRMED: 'Confirmed',
  DELIVERED: 'Delivered',
  HOLD_REQUEST: 'Hold Request',
  HOLD_REQUESTS: 'Hold Requests',
  HOLDS: 'Active Holds',
  IN_TRANSIT: 'In Transit',
  IN_CUSTOMS: 'In Customs',
  LOST: 'Lost',
  NOT_AVAILABLE: 'Not Available',
  OFFICE_INCOMING: 'Incoming',
  WAITING_FOR_CUSTOMER: 'Waiting For Customer',
  PURCHASE_ORDER: 'Purchase Order',
  READY_TO_COLLECT: 'Ready To Collect',
  READY_TO_SHIP: 'Ready To Ship',
  RETURN_REQUESTED: 'Return Requested',
  PENDING_RETURN: 'Pending return',
  IN_EXPRESS_INVENTORY: 'Express inventory',
  RETURN_ACCEPTED: 'Return Accepted',
  RETURN_DENIED: 'Return Denied',
  RETURN_IN_TRANSIT: 'Return In Transit',
  RETURN_AT_NIVODA: 'Return at Nivoda',
  RETURN_DELIVERED: 'Return Delivered',
  REGULAR_RETURN: 'REGULAR RETURN',
  READY_FINAL_DELIVERY: 'Ready To Ship / Deliver',
  SHIPPED_FINAL_DELIVERY: 'Shipped',
  SHIPPED: 'Shipped',
  SOLD_OUT: 'Sold Out',
  RETURNED: 'Returned',
  CUSTOMER_RETURNED: 'Customer Returned',
};

const returnStatusToTitleText = {
  CREATED: 'Initiated',
  QC_PENDING: 'QC Pending',
  QC_ACCEPTED: 'QC Passed',
  QC_REJECTED: 'QC Failed',
  RECEIVED: 'Received',
  RETURN_REJECTED: 'Rejected',
  RETURN_INITIATED: 'Initiated',
};

const globalInventoryStatusToTitle = {
  REQUESTED: 'Requested',
  APPROVED: 'Active',
  IN_TRANSIT: 'In Transit',
  COLLECTED: 'Collected',
  RETURNED_TO_SUPPLIER: 'Return To Supplier',
  ORDERED: 'Ordered',
  ON_MEMO: 'On Memo',
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  RECEIVED: 'Received',
  PENDING: 'Pending',
  SOLD: 'Sold',
  HOLD: 'Hold',
  PROCESSING: 'Processing',
};

const JewelleryStatusKeys = {
  ORDER_RECEIVED: 'Order Received',
  TO_INVOICE: 'To Invoice',
  INVOICED: 'Invoiced',
};

const JewelleryComponentsStatusKeys = {
  IN_PROGRESS: 'In Progress',
  AWAITING_CUSTOMER: 'Awaiting Customer',
  COMPLETED: 'Completed',
};

// This is for RequestStatus Mapper
export const statusMapper = {
  JEWELLERY: 'JEWELLERY',
  ...returnStatusToTitleText,
  ...ShipmentStatus,
  ...holdsTabStatusToTitle,
  ...holdStatusToTitle,
  ...holdExtensionStatusToTitle,
  ...JewelleryStatusKeys,
  ...JewelleryComponentsStatusKeys,
  PENDING_UPLOAD: 'Pending Upload',
  NIVODA_EXPRESS: 'Nivoda express',
  RETURN_SHIPMENT: 'Return shipment',
  REGULAR_SHIPMENT: 'Regular shipment',
  SUPPLIER_RETURN_SHIPMENT: 'Supplier return shipment',
  SUPPLIER_SHIPMENT: 'Supplier shipment',
  CUSTOMER_RETURN_SHIPMENT: 'Customer return shipment',
  CUSTOMER_SHIPMENT: 'Customer shipment',
  INTERCOMPANY_SHIPMENT: 'Inter-company shipment',
  FRESH_IMPORT: 'Fresh import',
  JEWELLERY_SUPPLIER_SHIPMENT: 'Jewellery Supplier Shipment',
  //Orders QC Table
  PHYSICAL_PENDING: 'Physical Pending ',
  ALL_PENDING: 'All Pending ',
  QC_PENDING: 'QC PENDING',
  QC_MISMATCH: 'QC MISMATCH',
  QC_REJECTED: 'QC FAIL',
  QC_PASSED: 'QC PASSED',
  // Invoices
  AWAITING: 'Awaiting payment',
  NOT_PAID: 'Awaiting payment',
  INV_NOT_PAID: 'Not paid',
  PAID: 'Paid',
  UNPAID: 'Unpaid',
  UN_PAID: 'Unpaid',
  OVERDUE: 'Overdue',
  PROCESSING_PAYMENT: 'Processing payment',
  PROCESSING: 'Processing',
  PARTIAL_PAID: 'Partially paid',
  PARTIAL_APPLIED: 'Partially allocated',
  INITIATED: 'In return',
  MANUAL_RECONCILED: 'Manual applied',
  AUTO_RECONCILED: 'Auto applied',
  CN_ALLOCATED: 'Allocated',
  CN_OPEN: 'Open',
  REFUNDED: 'Refunded',

  // Image Upload
  Linked: 'Linked',
  NotLinked: 'Not Linked',
  'Not Linked': 'Not Linked',

  // credit kyc assessment
  NOT_VERIFIED: 'Not Verified',
  REJECTED: 'Rejected',
  CREDIT_APPROVED: 'Approved',
  //Self confirm PO
  SELF_CONFIRM_PO: 'Self confirm PO',
  SUPPLIER_RAISE_ISSUE_PRICE_RELATED: 'Supp. Price Related',
  SUPPLIER_RAISE_ISSUE_SOLD_OUT: 'Spp. Sold Out',
  SUPPLIER_RAISE_ISSUE_OTHER: 'Supp. Other',
  // FEED
  APPROVED: 'Active',
  PENDING: 'Pending',
  REVOKED: 'Revoked',

  ACCEPTED: 'Accepted',
  DENIED: 'Denied',
  CREDIT_NOTE: 'Credit note',
  // Request
  AVAILABLE: 'Available',

  // Order
  ...orderStatusTextMapper,
  INTERNAL_ORDER: 'Inter office order',
  INTER_OFFICE_SHIPMENT: 'Inter office',
  HISTORIC: 'Historic',

  // Melee inventory status types
  LIVE: 'Live',
  SOURCE_TO_ORDER: 'Source to order',
  CONSIGNMENT_PERIOD_OVER: 'Consignment period over',
  CONSIGNMENT_RETURNED: 'Consignment returned',
  SPECIAL_REQUEST: 'Special request',
  STOCK_FINISHED: 'Out of stock',
  OTHER: 'Other',
  OPEN: 'Open',
  CLOSED: 'Closed',
  PARTIALLY_CLOSED: 'Partially closed',
  SENT: 'Sent',
  INVOICED: 'Invoiced',
  // Diamond Availability
  ...Object.keys(DiamondAvailability).reduce(
    (result, availability) => ({
      ...result,
      [availability]: capitalize(availability.replace('_', ' ')),
    }),
    {}
  ),

  // Enquiry
  [`${enquiryInternalStatusType.PENDING}_ENQUIRY`]: 'Enquiry Pending',
  [`${enquiryInternalStatusType.REQUESTED}_ENQUIRY`]: 'Enquiry Requested',
  [`${enquiryInternalStatusType.DONE}_ENQUIRY`]: 'Answered',
  IN_CONSIGNMENT: 'In consignment',
  RETURN_REQUESTED: 'Return requested',
  ...globalInventoryStatusToTitle,
};

export const config_status = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  WIP: 'WIP',
};

const statusToTitle = {
  [OrderItemStatus.AP_AVAILABLE]: 'Available',
  [OrderItemStatus.AP_AVAILABLE_HOLD]: 'Available + On hold',
  [OrderItemStatus.WAITING_FOR_CUSTOMER]: 'Waiting For Customer',
  [OrderItemStatus.CONFIRMED]: 'Confirm',
  [OrderItemStatus.CONFIRMED_READY_TO_COLLECT]: 'Confirm + RTC',
  [OrderItemStatus.CONFIRMED_READY_TO_SHIP]: 'Confirm + RTS',
  [OrderItemStatus.CONFIRMED_IN_TRANSIT]: 'Confirmed + In Transit',
  [OrderItemStatus.NOT_AVAILABLE]: 'Not Available',
  [OrderItemStatus.IN_TRANSIT]: 'In Transit',
  [OrderItemStatus.READY_TO_COLLECT]: 'Ready To Collect',
  [OrderItemStatus.COLLECTED]: 'Collected',
  [OrderItemStatus.TRANSIT_DONE]: 'Ready To Collect', // is this correct :/
  [OrderItemStatus.REPORT_PROBLEM]: 'Report Problem',
};

// Melee order actions
export const ORDER_ACTIONS = {
  map_status_to_title: statusToTitle,
  [OrderItemStatus.PURCHASE_ORDER]: [
    OrderItemStatus.WAITING_FOR_CUSTOMER,
    OrderItemStatus.CONFIRMED,
    OrderItemStatus.CONFIRMED_READY_TO_COLLECT,
    OrderItemStatus.CONFIRMED_READY_TO_SHIP, // not available for diamonds
    OrderItemStatus.CONFIRMED_IN_TRANSIT,
    OrderItemStatus.NOT_AVAILABLE,
  ],
  [OrderItemStatus.CONFIRMED]: [
    OrderItemStatus.IN_TRANSIT,
    OrderItemStatus.READY_TO_COLLECT,
  ],
  [OrderItemStatus.READY_TO_COLLECT]: [OrderItemStatus.COLLECTED],
  [OrderItemStatus.IN_TRANSIT]: [OrderItemStatus.TRANSIT_DONE],
};

export const COMMON_ORDER_ACTIONS = {
  [OrderItemStatus.WAITING_FOR_CUSTOMER]: [
    {
      id: OrderItemStatus.WAITING_FOR_CUSTOMER,
      title: 'Move to PO',
      style: {},
    },
    {
      id: OrderItemStatus.CONFIRMED,
      title: statusToTitle[OrderItemStatus.CONFIRMED],
      style: {},
    },
    {
      id: OrderItemStatus.CONFIRMED_READY_TO_COLLECT,
      title: statusToTitle[OrderItemStatus.CONFIRMED_READY_TO_COLLECT],
      style: {},
    },
    {
      id: OrderItemStatus.CONFIRMED_IN_TRANSIT,
      title: statusToTitle[OrderItemStatus.CONFIRMED_IN_TRANSIT],
      style: {},
    },
    {
      id: OrderItemStatus.NOT_AVAILABLE,
      title: statusToTitle[OrderItemStatus.NOT_AVAILABLE],
      style: {},
    },
  ],
  ['AP' + OrderItemStatus.WAITING_FOR_CUSTOMER]: [
    {
      id: OrderItemStatus.WAITING_FOR_CUSTOMER,
      title: 'Move to PO',
      style: {},
    },
    {
      id: OrderItemStatus.AP_AVAILABLE,
      title: statusToTitle[OrderItemStatus.AP_AVAILABLE],
      style: {},
    },
    {
      id: OrderItemStatus.AP_AVAILABLE_HOLD,
      title: statusToTitle[OrderItemStatus.AP_AVAILABLE_HOLD],
      style: {},
    },
    {
      id: OrderItemStatus.NOT_AVAILABLE,
      title: statusToTitle[OrderItemStatus.NOT_AVAILABLE],
      style: {},
    },
  ],
  [OrderItemStatus.PURCHASE_ORDER]: [
    {
      id: OrderItemStatus.WAITING_FOR_CUSTOMER,
      title: statusToTitle[OrderItemStatus.WAITING_FOR_CUSTOMER],
      style: {},
    },
    {
      id: OrderItemStatus.CONFIRMED,
      title: statusToTitle[OrderItemStatus.CONFIRMED],
      style: {},
    },
    {
      id: OrderItemStatus.CONFIRMED_READY_TO_COLLECT,
      title: statusToTitle[OrderItemStatus.CONFIRMED_READY_TO_COLLECT],
      style: {},
    },
    {
      id: OrderItemStatus.CONFIRMED_IN_TRANSIT,
      title: statusToTitle[OrderItemStatus.CONFIRMED_IN_TRANSIT],
      style: {},
    },
    {
      id: OrderItemStatus.NOT_AVAILABLE,
      title: statusToTitle[OrderItemStatus.NOT_AVAILABLE],
      style: {},
    },
    {
      id: OrderItemStatus.REPORT_PROBLEM,
      title: statusToTitle[OrderItemStatus.REPORT_PROBLEM],
      style: {},
    },
  ],
  ['AP' + OrderItemStatus.PURCHASE_ORDER]: [
    {
      id: OrderItemStatus.WAITING_FOR_CUSTOMER,
      title: statusToTitle[OrderItemStatus.WAITING_FOR_CUSTOMER],
      style: {},
    },
    {
      id: OrderItemStatus.AP_AVAILABLE,
      title: statusToTitle[OrderItemStatus.AP_AVAILABLE],
      style: {},
    },
    {
      id: OrderItemStatus.AP_AVAILABLE_HOLD,
      title: statusToTitle[OrderItemStatus.AP_AVAILABLE_HOLD],
      style: {},
    },
    {
      id: OrderItemStatus.NOT_AVAILABLE,
      title: statusToTitle[OrderItemStatus.NOT_AVAILABLE],
      style: {},
    },
    {
      id: OrderItemStatus.REPORT_PROBLEM,
      title: statusToTitle[OrderItemStatus.REPORT_PROBLEM],
      style: {},
    },
  ],
  // Nivoda internal supplier
  [OrderItemStatus.CONFIRMED]: [
    {
      id: OrderItemStatus.IN_TRANSIT,
      title: statusToTitle[OrderItemStatus.IN_TRANSIT],
      style: {},
    },
    {
      id: OrderItemStatus.READY_TO_COLLECT,
      title: statusToTitle[OrderItemStatus.READY_TO_COLLECT],
      style: {},
    },
  ],
  [OrderItemStatus.READY_TO_COLLECT]: [
    {
      id: OrderItemStatus.COLLECTED,
      title: statusToTitle[OrderItemStatus.COLLECTED],
      style: {},
    },
    {
      id: OrderItemStatus.IN_TRANSIT,
      title: statusToTitle[OrderItemStatus.IN_TRANSIT],
      style: {},
    },
  ],
  [OrderItemStatus.IN_TRANSIT]: [
    {
      id: OrderItemStatus.TRANSIT_DONE,
      title: statusToTitle[OrderItemStatus.TRANSIT_DONE],
      style: {},
    },
  ],
};

// Melee Repository page Bulk Status update options
export const MELEE_REPOSITORY_STATUSES = {
  ACTIVE: {
    id: 'active',
    label: 'Active',
    value: 'ACTIVE',
  },
  INACTIVE: {
    id: 'inActive',
    label: 'Inactive',
    value: 'INACTIVE',
  },
  LIVE: {
    id: 'live',
    label: 'Live',
    value: 'LIVE',
  },
  STOCK_FINISHED: {
    id: 'outOfStock',
    label: 'Out of Stock',
    value: 'STOCK_FINISHED',
  },
  CONSIGNMENT_RETURNED: {
    id: 'consignmentReturned',
    label: 'Consignment Returned',
    value: 'CONSIGNMENT_RETURNED',
  },
};
