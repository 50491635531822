import theme from 'fabric/theme';
import { styled_predicate } from 'helpers';
const { default: styled, css } = require('styled-components');

export const DropdownButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 40px;
  background: ${(props) => (props.isActive ? ' #e9f2fd' : '#ffffff')};
  border-radius: 4px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => (props.isActive ? '#424243' : '#424243')};
  stroke-width: 2;
  .icon {
    color: ${(props) => (props.isActive ? '#424243' : '#424243')};
  }
  .filter-name {
    margin-right: 8px;
  }

  transition: all 0.2s ease-in;
  ${styled_predicate((p) => !p.isActive)} {
    &:hover {
      background: #e9f2fd;
    }
  }
  .icon {
    transition: transform 0.2s;
  }
  ${styled_predicate((p) => p.is_open)} {
    .icon {
      transform: rotate(-180deg);
    }
  }
`;

export const ModalTabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FilterContainer = styled.div`
  user-select: none;
  position: relative;
  .flex {
    display: flex;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .flex-1 {
    flex: 1;
  }

  .shape-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    align-items: center;
    padding: 0px;
    gap: 8px 16px;
    .shape-item {
      cursor: pointer;
      display: grid;
      grid-template-columns: 30px 1fr 20px;
      align-items: center;
      padding: 10px 6px;
      gap: 1px;
      background-color: white;
      &:hover {
        background-color: #e9f2fd;
      }
      transition: background-color 0.3s ease-in;
    }
    .active {
      background-color: #e9f2fd;
    }
  }

  .sort-items {
    display: flex;
    flex-direction: column;
    /* gap: 16px; */
    .sort-item {
      padding: 20px 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #424243;
      height: 24px;
      font-weight: 400;
      font-size: 14px;
      &:hover {
        background: #e9f2fd;
      }
      &.selected-val {
        background: #e9f2fd;
      }
    }
  }
  .default-sorting-option {
    padding: 10px 10px 0;
    .default-sorting-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: #0c0a09;
      border: none;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border-radius: 4px;
      color: #fff;
      line-height: 22px;
      height: 35px;
      padding: 5px;
      &:hover {
        box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
        background-color: #0c0a09;
      }
    }
  }

  .sortby__button-full_text {
    display: block;
  }
  .sortby__button-only_icon {
    display: none;
  }

  @media (max-device-width: 1024px) {
    .sortby__button-full_text {
      display: none;
    }
    .sortby__button-only_icon {
      display: inline-flex;
    }
    .sort_by-modal {
      right: 0;
    }
  }
`;

export const FilterBox = styled.div`
  display: flex;

  flex-direction: column;
  .filter-grid-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #424243;
    margin-bottom: 8px;
  }
`;

export const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.col
      ? `repeat(${props.col}, 1fr)`
      : `repeat(auto-fill, minmax(${props.minmax || '80px'}, 1fr))`};

  flex-wrap: ${(props) => props.flex_wrap || 'wrap'};
  align-items: ${(props) => props.align_items || 'center'};
  padding: ${(props) => props.padding || '0px'};
  gap: ${(props) => props.gap || '8px 16px'};
  .span_3 {
    grid-column: span 3;
  }
  .filter-grid-item {
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify_content || 'center'};
    background: ${({ is_active }) => (is_active ? '#e9f2fd' : '#fff')};
    border: 1px solid #e0e7ec;
    border-radius: 4px;
    padding: 12px 16px;

    cursor: pointer;
    .item-label {
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }
    transition: background 0.2s;
    &:hover {
      background: #e9f2fd;
    }
  }
  .filter-grid-item.active_btn {
    background: #e9f2fd;
  }
`;
export let NameSpan = styled.span`
  @media (max-width: 1234px) {
    .showOnBigScreen {
      display: none;
    }
  }
`;
export let FLourNameSpan = styled.span`
  @media (max-width: 1234px) {
    .showOnBigScreen {
      display: none;
    }
    .showOnSmallScreen {
      display: block;
    }
  }
  @media (min-width: 1234px) {
    .showOnBigScreen {
      display: block;
    }
    .showOnSmallScreen {
      display: none;
    }
  }
`;

export const CaratOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding-bottom: 8px; */
  /* width: 344px; */
  justify-content: flex-start;
  > div:not(:last-child) {
    margin-right: 8px;
  }
  .option-lable-and-field {
    display: flex;
    align-items: center;

    .option-label {
      font-weight: 400;
      font-size: 14px;
      color: #334155;
      margin-right: 5px;
    }
    .option-input {
      width: 100%;
      border: none;
    }

    .option-base-field {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 4px 8px;
      border: 1px solid #e0e7ec;
      border-radius: 2px;
      background: #ffffff;
      .field-text {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const FilterModal = styled.div`
  box-sizing: border-box;
  max-height: 600px;
  overflow: ${(props) => props.overFlow || 'auto'};
  z-index: 20;
  top: 40px;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding || '0px'};

  position: absolute;
  width: ${(props) => props.width || 'max-content'};
  height: ${(props) => props.height || 'max-content'};
  background: #fcfcfc;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 24px 56px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  min-width: ${(props) => props.minWidth || 'none'};
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 24px;
  background: #dadada;
  margin: 0 4px;
`;

export const DefaultButton = styled.button`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  height: ${(props) => props.height};
  min-width: ${(props) => props.width};
  margin-left: ${({ left = '15px' }) => left};
  margin-bottom: ${({ bottom }) => bottom};
  padding: ${(props) => props.padding};
  border-radius: ${(props) => props.borderRadius || '8px'};
  font-weight: ${(props) => props.fontWeight || 500} !important;
  font-size: ${(props) => props.fontSize || '14px'};
  line-height: ${(props) => props.lineHeight || '14px'};
  outline: none !important;
  cursor: ${({ cursor }) => cursor || 'pointer'};
  ${({ variant }) =>
    variant === 'default'
      ? css`
          background: ${({ disabled, isActive, transparent }) =>
            transparent
              ? 'transparent'
              : disabled
              ? '#fafafa'
              : isActive
              ? '#FAFAFA'
              : '#ffffff'};
          border: ${({ disabled, isActive }) =>
            disabled
              ? '1px solid #E4E4E7'
              : isActive
              ? '1px solid #A1A1AA'
              : '1px solid #d4d4d8'};
          color: ${({ disabled }) => (disabled ? '#D4D4D8' : '#27272a')};
          ${({ noHover }) =>
            !noHover &&
            css`
              &:hover {
                background: ${({ isActive, disabled, transparent }) =>
                  transparent
                    ? 'transaprent'
                    : !isActive && !disabled && '#FFFFFF'};
                border: ${({ isActive, disabled }) =>
                  !isActive &&
                  !disabled &&
                  `1px solid ${theme.palette.violet50} !important`};
              }
            `}
        `
      : variant === 'transparent'
      ? css`
          background: transparent;
          border: 1px solid transparent;
          color: #27272a;
          &:hover {
            background: rgba(24, 24, 27, 0.03);
            border: 1px solid rgba(24, 24, 27, 0.03);
          }
        `
      : variant === 'ghost'
      ? css`
          background: transparent;
          border: 1px solid transparent;
          color: #27272a;
          &:hover {
            color: ${theme.palette.themeSecondary};
          }
        `
      : variant === 'primary'
      ? css`
          background: #0c0a09;
          border: 1px solid #0c0a09;
          color: #ffffff;
          &:hover {
            background: ${({ isActive }) => !isActive && '#292524'};
            border: ${({ isActive }) => !isActive && '1px solid #292524'};
            color: #ffffff;
          }
          &:disabled {
            cursor: not-allowed;
            background: #292524;
          }
        `
      : variant === 'secondary'
      ? css`
          border: 1px solid ${theme.palette.borderHard};
          background: #fff;
          color: ${theme.palette.Neutrals700};
          box-shadow: none;
          &:hover:not(:disabled) {
            background: #fff;
            border: 1px solid ${theme.palette.themeSecondary};
            color: ${theme.palette.violet700} !important;
          }
          &:active {
            background: ${theme.palette.violet};
          }
          &:disabled {
            cursor: not-allowed;
            background: #fff !important;
            border: 1px solid #d6d3d1 !important;
            color: ${theme.palette.Neutrals700};
          }
        `
      : // css`background: ${({ isActive }) => (isActive ? '#DDE2EE' : '#ffffff')};
      // border: ${({ isActive }) =>
      //   isActive ? '1px solid #6B80AE' : '1px solid #DDE2EE'}
      // color: ${(props) => props.textColor};
      // &:hover{
      //   background:${({ isActive }) => (isActive ? '#DDE2EE' : '#fff')};

      //   border:${({ isActive }) =>
      //     isActive ? '1px solid #6B80AE' : '1px solid #A1A1AA'};
      // }`
      variant === 'specialRequest'
      ? css`
          background: #ffffff;
          border: ${({ isActive }) =>
            isActive
              ? `2px solid ${theme.palette.themeSecondary}`
              : '1px solid #DDE2EE'}
          color: ${(props) => props.textColor};
          &:hover{
            background: #ffffff;
            // background:${({ isActive }) => !isActive && '#FAFAF9'};
            border: 2px solid ${theme.palette.themeSecondary};
          }
        `
      : variant === 'classic'
      ? css`
          background: ${({ disabled, isActive }) =>
            disabled
              ? '#fafafa'
              : isActive
              ? theme.palette.themePrimary
              : '#ffffff'};
          border: ${({ disabled, isActive }) =>
            disabled
              ? '1px solid #E4E4E7'
              : isActive
              ? `1px solid ${theme.palette.themePrimary}`
              : '1px solid #d4d4d8'};
          color: ${({ disabled, isActive }) =>
            disabled ? '#D4D4D8' : isActive ? theme.palette.white : '#27272a'};
          svg {
            path {
              color: ${({ disabled, isActive }) =>
                disabled
                  ? '#D4D4D8'
                  : isActive
                  ? theme.palette.white
                  : '#27272a'};
            }
          }
          ${({ noHover }) =>
            !noHover &&
            css`
              &:hover {
                background: ${({ isActive, disabled }) =>
                  !isActive && !disabled && '#FFFFFF'};
                border: ${({ isActive, disabled }) =>
                  !isActive && !disabled && '1px solid #A1A1AA'};
              }
            `}
        `
      : variant === 'danger'
      ? css`
          background: #ffffff;
          border: 1px solid #ec0e0e1f;
          color: #ff0000;
          &:hover {
            background: #ffffff;
            border: 1px solid #ff00006e;
          }
        `
      : css`
          border: 1px solid ${(props) => props.borderColor || '#fff'};
          background: ${(props) => props.backgroundColor || '#fff'};
          color: ${(props) => props.textColor};
          &:disabled {
            cursor: not-allowed;
          }
          ${styled_predicate((p) => !p.is_selected)} {
            &:hover {
              background-color: ${(props) =>
                props.hoverBg || theme.palette.themeLight};
              border: ${(props) => `1px solid ${props.hoverBorderColor}`};
              color: ${(props) => props.hoverTextColor};
              .svg_icon {
                color: ${(props) => props.hoverSvgIconColor};
              }
            }
          }
        `}
  .default-btn--label {
    color: ${({ isActive }) =>
      isActive ? theme.palette.themeSecondary : theme.palette.black};
    ${({ capitalize }) =>
      capitalize &&
      css`
        text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
      `};
  }
  .show-on-web {
    display: block;
  }
  @media (max-width: 1153px) {
    .show-on-web {
      display: none;
    }
  }
`;

export const SortWrapper = styled(DefaultButton)`
  font-weight: 400;

  .heavy {
    font-weight: 700;
  }
  .chevron {
    transition: transform 0.2s;
  }
  .text-title {
    padding: 0 5px;
  }
  .direction-full {
    display: inline;
  }
  .direction-mini {
    display: none;
  }

  @media (max-width: 1234px) {
    .direction-full {
      display: none;
    }
    .direction-mini {
      display: inline;
    }
  }

  ${styled_predicate((p) => p.is_open)} {
    .chevron {
      transform: rotate(-180deg);
    }
  }
`;

export const DropdownSelectWrapper = styled.div`
  .Select {
    border-radius: 8px;
  }
  .Select-control {
    border-radius: 8px !important;
    border: 1px solid #e0e7ec !important;
    box-shadow: none !important;
    .Select-placeholder {
      display: flex;
      height: 90%;
      align-items: center;
    }
  }
`;

// .Select-multi-value-wrapper {
//   // height: 28px;
//   // border: 1px solid;
//   //   align-items: baseline;
//   //   margin-top: 5px !important;
// }
// .Select-value {
//   // line-height: 0 !important;
//   //   padding-left: 4px;
//   //   padding-right: 4px;
//   //   height: 28px;
//   //   margin-bottom: 0;
// }

export const ToggleWrapper = styled.div`
  .togglerContainer {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.spacing.s2};
    background: ${({ bgColor }) => bgColor};
  }

  .togglerBtn {
    height: 36px;
    min-width: 96px;
    border: unset;
    min-width: 96px;
    font-size: 14px;
    padding: 10px 12px;
    color: ${({ color }) => color};
    border-radius: ${theme.spacing.s1};

    :hover {
      border: unset !important;
    }

    &.active {
      color: ${theme.palette.white};
    }

    @media (max-width: 1153px) {
      min-width: 30px;
    }
  }

  .togglerBtnText {
    font-weight: 500;
  }
`;

export const commonThemeCSS = () => css`
   {
    color: ${({ themeColor }) => themeColor} !important;
    border-color: ${({ themeColor2 }) => themeColor2} !important;

    svg {
      path {
        color: ${({ themeColor }) => themeColor} !important;
      }
    }

    span {
      color: ${({ themeColor }) => themeColor} !important;
    }
  }
`;

export const ThemedDefaultButton = styled(DefaultButton)`
  &:hover {
    ${commonThemeCSS()};
  }
  ${({ isActive }) =>
    isActive &&
    css`
       {
        ${commonThemeCSS()};
        background: ${({ themeBg }) => themeBg} !important;
      }
    `}

  @media (max-width:640px) {
    display: none;
  }
`;
