import { getData, overwrite } from 'country-list';
import { allCountries } from 'country-region-data';
import { CustomTrans } from 'helpers';

export const firstTouchOptions = (t) => {
  return [
    {
      value: 'Google',
      label: t('google_search'),
    },
    {
      value: 'Word of Mouth',
      label: t('word_of_mouth'),
    },
    {
      value: 'Supplier Referral',
      label: t('supplier_referral'),
    },
    {
      value: 'Buyer Referral',
      label: t('customer_referral'),
    },
    {
      label: t('call_mail_from_sales_team'),
      value: 'Nivoda Email / Phone Call',
    },
    {
      label: t('email_marketing_newsletter'),
      value: 'Email Marketing / Newsletter',
    },
    {
      label: t('flyer'),
      value: 'Flyer',
    },
    {
      label: t('industry_magazine'),
      value: 'Industry Magazine / Web Article / Newsletter',
    },
    {
      value: 'LinkedIn',
      label: t('linkedin'),
    },
    {
      value: 'Facebook',
      label: t('facebook'),
    },
    {
      value: 'Instagram',
      label: t('instagram'),
    },
    {
      value: 'Industry Event',
      label: t('industry_event'),
    },
    {
      value: 'Other',
      label: t('other'),
    },
  ];
};

// customers company type options
const companyTypeOptions = (t) => {
  return [
    {
      value: 'Brick and mortar store',
      label: `${t('brick_mortar')} ${t('store')}`,
      type: 'CUSTOMER',
    },
    { value: 'Chain Store', label: t('chain_store'), type: 'CUSTOMER' },
    { value: 'Online Retailer', label: t('online_retailer'), type: 'CUSTOMER' },
    { value: 'Hybrid', label: t('hybrid'), type: 'CUSTOMER' },
    { value: 'Diamond Dealer', label: t('diamond_dealer'), type: 'CUSTOMER' },
    {
      value: 'Manufacturer',
      label: t('jewelry_manufacturer'),
      type: 'CUSTOMER',
    },
    {
      value: 'Private Jeweller',
      label: t('private_jeweller'),
      type: 'CUSTOMER',
    },
    { value: 'Appraiser', label: t('appraiser'), type: 'CUSTOMER' },
    { value: 'Designer', label: t('designer'), type: 'CUSTOMER' },
    {
      value: 'Natural Diamond Manufacturer',
      label: t('natural_diamond_manufacturer'),
      type: 'SUPPLIER',
    },
    {
      value: 'Natural Diamond Trader',
      label: t('natural_diamond_trader'),
      type: 'SUPPLIER',
    },
    {
      value: 'Star & Melee Manufacturer',
      label: t('star_melee_manufacturer'),
      type: 'SUPPLIER',
    },
    {
      value: 'Star & Melee Trader',
      label: t('star_melee_trader'),
      type: 'SUPPLIER',
    },
    {
      value: 'Lab Grown Diamond Manufacturer',
      label: t('lab_grown_diamond_manufacturer'),
      type: 'SUPPLIER',
    },
    {
      value: 'Lab Grown Diamond Trader',
      label: t('lab_grown_diamond_trader'),
      type: 'SUPPLIER',
    },
    {
      value: 'Gemstone Trader',
      label: 'Gemstone Trader',
      type: 'SUPPLIER',
    },
    {
      value: 'Gemstone Manufacturer',
      label: 'Gemstone Manufacturer',
      type: 'SUPPLIER',
    },
    { value: 'Other', label: t('other'), type: 'CUSTOMER' },
  ];
};
//pass the role to get respective options for that customer role
export const getCompanyOptions = (role = 'CUSTOMER', t) =>
  companyTypeOptions(t).filter(({ type }) => type === role);

export const customerTitleOptions = () => {
  return [
    {
      value: 'Buyer',
      label: <CustomTrans text={'buyer'} returnPlainText={true} />,
    },
    {
      value: 'Finance',
      label: <CustomTrans text={'finance'} returnPlainText={true} />,
    },
    {
      value: 'Manager',
      label: <CustomTrans text={'manager'} returnPlainText={true} />,
    },
    {
      value: 'Owner / CEO',
      label: `${CustomTrans({
        text: 'owner',
        returnPlainText: true,
      })}/${CustomTrans({ text: 'ceo', returnPlainText: true })}`,
    },
    {
      value: 'Sales / Marketing',
      label: `${CustomTrans({
        text: 'sales',
        returnPlainText: true,
      })}/${CustomTrans({ text: 'marketing', returnPlainText: true })}`,
    },
    {
      value: 'Stock Manager / Merchandising',
      label: `${CustomTrans({
        text: 'stock_manager',
        returnPlainText: true,
      })}/${CustomTrans({ text: 'merchandising', returnPlainText: true })}`,
    },
    {
      value: 'Tech',
      label: <CustomTrans text={'tech'} returnPlainText={true} />,
    },
  ];
};

export const countryOptions = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  {
    value: 'AU',
    label: 'Australia',
    company_reg_no: { local: 'Australian Company Number (ACN)', english: '' },
  },
  {
    value: 'AT',
    label: 'Austria',
    company_reg_no: {
      local: 'Firmenbuchnummer',
      english: 'Commercial Register Number',
    },
  },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  {
    value: 'BE',
    label: 'Belgium',
    company_reg_no: {
      local: 'BCE (Banque-Carrefour des Entreprises)',
      english: 'BCE Number',
    },
  },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  {
    value: 'IN',
    label: 'India',
    company_reg_no: { local: 'Corporate Identity Number', english: '' },
  },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  {
    value: 'BG',
    label: 'Bulgaria',
    company_reg_no: {
      local: 'BULSTAT code',
      english: 'Company Registration Number',
    },
  },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo, Republic of the (Brazzaville)' },
  { value: 'CD', label: 'Congo, the Democratic Republic of the (Kinshasa' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: `Côte d'Ivoire, Republic of` },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  {
    value: 'CZ',
    label: 'Czech Republic',
    company_reg_no: {
      local: 'Identifikačné číslo organizácie - IČO',
      english: 'National Business ID',
    },
  },
  {
    value: 'DK',
    label: 'Denmark',
    company_reg_no: {
      local: 'CVR (Centrale Virksomhedsregister)',
      english: 'Central Business Number',
    },
  },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  {
    value: 'EE',
    label: 'Estonia',
    company_reg_no: { local: 'Commercial Register Number', english: '' },
  },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Islas Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  {
    value: 'FI',
    label: 'Finland',
    company_reg_no: {
      local: 'Business ID Number',
      english: '',
    },
  },
  {
    value: 'FR',
    label: 'France',
    company_reg_no: {
      local: "SIREN (Système d'identification du répertoire des entreprises)",
      english: 'SIREN Number',
    },
  },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern and Antarctic Lands' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia, The' },
  { value: 'GE', label: 'Georgia' },
  {
    value: 'DE',
    label: 'Germany',
    company_reg_no: {
      local: 'Handelsregisternummer',
      english: 'Register Number',
    },
  },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  {
    value: 'GR',
    label: 'Greece',
    company_reg_no: {
      local: 'General Commercial Registry Number',
      english: '',
    },
  },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  {
    value: 'HU',
    label: 'Hungary',
    company_reg_no: {
      local: 'Cégjegyzékszám',
      english: 'Company Registration Number',
    },
  },
  { value: 'IS', label: 'Iceland' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran, Islamic Republic of' },
  { value: 'IQ', label: 'Iraq' },
  {
    value: 'IE',
    label: 'Ireland',
    company_reg_no: { local: 'Companies Registration Number', english: '' },
  },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  {
    value: 'IT',
    label: 'Italy',
    company_reg_no: { local: 'Partita IVA', english: '' },
  },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: `Korea, Democratic People's Republic of` },
  { value: 'KR', label: 'Korea, Republic of' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  {
    value: 'LU',
    label: 'Luxembourg',
    company_reg_no: {
      local: 'Registre de Commerce et des Sociétés',
      english: 'Registration Number',
    },
  },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macedonia, Republic of' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia, Federated States of' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  {
    value: 'NL',
    label: 'Netherlands',
    company_reg_no: {
      local: 'KVK (Kamer van Koophandel)',
      english: 'Chamber of Commerce Number',
    },
  },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  {
    value: 'NO',
    label: 'Norway',
    company_reg_no: {
      local: 'Organisajonsnummer',
      english: 'Register of Business Enterprises Number',
    },
  },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine, State of' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  {
    value: 'PL',
    label: 'Poland',
    company_reg_no: {
      local: 'KRS (Krajowy Rejestr Sądowy)',
      english: 'National Court Register Number',
    },
  },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  {
    value: 'RO',
    label: 'Romania',
    company_reg_no: { local: 'Trade Register Number', english: '' },
  },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  {
    value: 'ES',
    label: 'Spain',
    company_reg_no: {
      local: 'CIF (Certificado de Identificación Fiscal)',
      english: 'Company Identification Number',
    },
  },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SZ', label: 'Swaziland' },
  {
    value: 'SE',
    label: 'Sweden',
    company_reg_no: {
      local: 'Organisationsnummer',
      english: 'Corporate Identity Number',
    },
  },
  {
    value: 'CH',
    label: 'Switzerland',
    company_reg_no: { local: 'Uniform Identification Number', english: '' },
  },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania, United Republic of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  {
    value: 'GB',
    label: 'United Kingdom',
    company_reg_no: { local: 'Companies House Number', english: '' },
  },
  {
    value: 'US',
    label: 'United States',
    company_reg_no: { local: 'Employer Identification Number', english: '' },
  },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VG', label: 'Virgin Islands, British' },
  { value: 'VI', label: 'Virgin Islands, U.S.' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];

export const collectionUpdatesOptions = [
  { value: 'None', label: 'None' },
  { value: 'No Contact', label: 'No Contact' },
  { value: 'Left Message', label: 'Left Message' },
  { value: 'Voice Mail', label: 'Voice Mail' },
  { value: 'Follow Up', label: 'Follow Up' },
  { value: 'Invalid Contact', label: 'Invalid Contact' },
  { value: 'Escalated', label: 'Escalated' },
];

export const accountFlaggingOptions = [
  { value: 'None', label: 'None' },
  { value: 'Fraud', label: 'Fraud' },
  { value: 'Bankruptcy', label: 'Bankruptcy' },
  { value: 'Sent to Collections', label: 'Sent to Collections' },
  { value: 'Do Not Fund', label: 'Do Not Fund' },
  { value: 'Legal Action', label: 'Legal Actions' },
];

export const monthlyAvgOptions = [
  { value: '0-10k', label: '0-10k' },
  { value: '10-25k', label: '10-25k' },
  { value: '25-50k', label: '25-50k' },
  { value: '50-100k', label: '50-100k' },
  { value: '100-300k', label: '100-300k' },
  { value: '300k+', label: '300k+' },
  { value: '400k+', label: '400k+' },
  { value: '500k+', label: '500k+' },
  { value: '1-5m', label: '1-5m' },
  { value: '5m+', label: '5m+' },
];

export const companySizeOptions = [
  { value: '0-1M', label: 'Small enterprise ($0 - 1M)' },
  { value: '1-5M', label: 'Medium enterprise ($1M - 5M)' },
  { value: '5-50M', label: 'Large enterprise ($5M - $50M)' },
  { value: '50M+', label: 'Enterprise ($50M+)' },
];

export const monthlyAvgSaleOptions = [
  {
    value: '1-4',
    label: '1-4',
  },
  {
    value: '5-20',
    label: '5-20',
  },
  {
    value: '21-50',
    label: '21-50',
  },
  {
    value: '51-100',
    label: '51-100',
  },
  {
    value: '101-250',
    label: '101-250',
  },
  {
    value: '251-500',
    label: '251-500',
  },
  {
    value: '501-3000',
    label: '501-3000',
  },
  {
    value: '3000+',
    label: '3000+',
  },
];

export const BlockedReasons = [
  { value: 'Price Issue', label: 'Price Issue' },
  { value: 'Sold Out Issue', label: 'Sold Out Issue' },
  { value: 'Payment Terms Issue', label: 'Payment Terms Issue' },
  { value: 'Return Terms Issue', label: 'Return Terms Issue' },
  { value: 'Supplier Tech Issue', label: 'Supplier Tech Issue' },
  { value: 'Supplier Not available', label: 'Supplier Not available' },
  { value: 'File not Updated', label: 'File not Updated' },
  { value: 'Not Onboarded supplier', label: 'Not Onboarded supplier' },
  { value: 'Behavioural Issue', label: 'Behavioural Issue' },
  { value: 'Hard Block- Issues', label: 'Hard Block- Issues' },
  { value: 'Others', label: 'Others' },
];

export const ROLES = {
  SUPPLIER: 'SUPPLIER',
  CUSTOMER: 'CUSTOMER',
};

overwrite([
  {
    code: 'US',
    name: 'United States of America',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
  },
  {
    code: 'TR',
    name: 'Turkiye',
  },
]);

export const ALL_COUNTRIES = getData().map((ele) => ({
  label: ele.name,
  value: ele.code,
}));

export const getRegionDataOnCountry = (country) => {
  return allCountries
    .find((ele) => ele[1] === country)[2]
    ?.map((ele) => ({ label: ele[0], value: ele[1] }));
};

export const invoicePerShipment = [
  { value: 'none', label: 'None' },
  { value: 'invoice_per_shipment', label: 'Generate Invoice Per Shipment' },
  {
    value: 'invoice_last_leg',
    label: 'Generate Invoice on Last leg shipment only',
  },
];
