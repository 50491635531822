import React, { useState } from 'react';
import './page-header.css';
import { WithHistory } from '../../Elements';
import backIcon from 'fabric/resources/svg/ui-icons/back-arrow.svg';
import { RefreshButton } from '@nivoda/ui';
import KYCBadge from 'Admin/components/Company/single-company/Badges';
import { Icon } from 'fabric/resources/icon';
import theme from 'fabric/theme';
import styled from 'styled-components';
import { Button } from 'fabric/components';
import { useMutation } from '@apollo/client';
import { adminGraphqlClient } from 'graphql/factory';
import { UPDATE_COMPANY_DETAILS } from 'graphql/mutations/admin/company';
import { useErrorWrapper } from 'fabric/hooks';
import RiskCollectionBadge from 'Admin/components/Company/single-company/RiskCollectionBadge';
import CopyToClipboard from 'NewDashboard/components/copy-to-clipboard';
// import { usePermissions } from 'context/permissions';

export const PageHeader = (props) => {
  const {
    items,
    title,
    id,
    subtitle,
    tabs,
    tabClick,
    activeTab,
    backToComp,
    canSearch,
    doSearch,
    headerButton,
    isRefreshButton = false,
    certNumber = '',
    showCertNum = false,
    is_kyc_verified,
    is_fraudulent,
    fraudulent_date,
    is_flagged,
    account_flagged,
    flagged_date,
    show_badge,
    copyText,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [fnWrapper, context] = useErrorWrapper({ useToastNotification: true });
  const [updateCompanyName] = useMutation(UPDATE_COMPANY_DETAILS, {
    client: adminGraphqlClient,
  });
  // let { scopes = [] } = usePermissions();

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedTitle(title);
  };

  const handleTitleChange = (event) => {
    if (event && event.target) {
      setEditedTitle(event.target.value);
    } else {
      // eslint-disable-next-line no-console
      console.error('Event or event.target is undefined');
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    await fnWrapper(
      async () => {
        context.setBusy({ type: 'update company name', status: true });
        await updateCompanyName({
          variables: {
            companyDetails: { company_id: id, name: editedTitle },
          },
        });
        setIsEditing(false);
        context.setBusy({ type: '', status: false });
      },
      null,
      {
        success: {
          messageResolver: () => 'Successfully saved',
        },
        error: {
          messageResolver: (msg) =>
            msg ?? 'There was an error updating the company name.',
        },
      }
    );
  };

  return (
    <React.Fragment>
      <div
        className="order-overview__header"
        style={{ gap: '15px', marginTop: '20px' }}
      >
        <WithHistory>
          {(history) => (
            <button
              className="order-overview__back-button"
              onClick={() => (backToComp ? backToComp() : history.goBack())}
              data-automation-id="back-btn"
            >
              <img
                src={backIcon}
                alt="back"
                className="order-overview__back-arrow"
              />
              Back
            </button>
          )}
        </WithHistory>
        <PageHeaderWrapper>
          {isEditing ? (
            <>
              <input
                type="text"
                value={editedTitle}
                onChange={handleTitleChange}
                style={{ width: 350, height: '40px' }}
              />
              <Button
                style={{ height: '50%' }}
                onClick={handleSave}
                disabled={context.busy.status}
                className="btn-overide"
              >
                {context.busy.status ? (
                  <Icon name="loader" color="white" />
                ) : (
                  <span>Save</span>
                )}
              </Button>
            </>
          ) : (
            <>
              <strong
                className="order-overview__title"
                style={{
                  width:
                    title === 'All Shipments'
                      ? '130px'
                      : showCertNum
                      ? 'auto'
                      : '100%',
                  display: 'flex',
                  gap: '10px',
                }}
              >
                {title}
                {show_badge && !is_kyc_verified && (
                  <KYCBadge is_kyc_verified={is_kyc_verified} />
                )}
                {is_flagged && !is_fraudulent && (
                  <RiskCollectionBadge
                    text={`Account Flagged as ${account_flagged} on ${flagged_date}`}
                  />
                )}
                {is_fraudulent && (
                  <RiskCollectionBadge
                    text={`Fraud: Permanently Restricted since ${fraudulent_date}`}
                  />
                )}
                {copyText?.orderNumber && (
                  <CopyToClipboard
                    iconName={'copyIcon'}
                    iconHeight={16}
                    iconWidth={16}
                    iconColor={'#FFF'}
                    text={copyText?.orderNumber}
                    placement={'bottom'}
                  />
                )}
              </strong>
              <Icon
                className="edit-pencil"
                data-automation-id="edit-icon"
                name={'edit-pencil'}
                style={{ marginLeft: '5px' }}
                color={theme.palette.themePrimary}
                hoverColor={`${theme.palette.themePrimary}cc`}
                onClick={handleEditClick}
              />
            </>
          )}
        </PageHeaderWrapper>

        {showCertNum && (
          <strong className="order-overview__title">{certNumber}</strong>
        )}
        {copyText?.certNumber && (
          <CopyToClipboard
            iconName={'copyIcon'}
            iconHeight={16}
            iconWidth={16}
            iconColor={'#FFF'}
            text={copyText?.certNumber}
            placement={'bottom'}
          />
        )}
        {items && (
          <span className="pages-header__items">
            {items === 1 ? '(1 item)' : `(${items} items)`}
          </span>
        )}

        {tabs && (
          <div className="header_tabs">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => tabClick(tab)}
                className={
                  activeTab === tab ? 'header_tab active' : 'header_tab'
                }
              >
                {tab}
              </button>
            ))}
          </div>
        )}
        {canSearch && (
          <input
            onChange={(e) => doSearch(e.target.value)}
            className="order-overview__header-search pages-header__search"
            type="text"
            placeholder="Search"
          />
        )}
        {isRefreshButton && (
          <RefreshButton label={'enquiries'} title="enquiries items list" />
        )}
        {headerButton && (
          <div className="pages-header__button" style={{ marginLeft: 'auto' }}>
            {headerButton}
          </div>
        )}
      </div>

      {subtitle && <div className="pages-header__subtitle">{subtitle}</div>}
    </React.Fragment>
  );
};

// let forceDownload = (url) => {
//   if (!url) {
//     throw new Error('Invalid S3 URL');
//   }
//   let xhr = new XMLHttpRequest();
//   xhr.open('GET', 'https://cors-anywhere.herokuapp.com/' + url, true);
//   xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
//   xhr.responseType = 'blob';
//   xhr.onload = (response) => {
//     let urlCreator = window.URL || window.webkitURL;
//     let imageUrl = urlCreator.createObjectURL(response.currentTarget.response);
//     let tag = document.createElement('a');
//     tag.href = imageUrl;
//     tag.download = 'pph_insurance_form.docx';
//     document.body.appendChild(tag);
//     tag.click();
//     document.body.removeChild(tag);
//   };
//   xhr.send();
// };

const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  .btn-overide {
    padding: 8px 20px !important;
    height: 40px !important;
  }
`;
