import { gql } from '@apollo/client';
import {
  AdminMeleeRepoItemFragment,
  AdminSrLinkStockIdFragment,
  MeleeFragment,
  NoteFragment,
} from '../../fragments';

export const GET_ALL_MELEE_SUPPLIERS = gql`
  query getAllMeleeSuppliers($supplierType: SupplierType, $fetchAll: Boolean) {
    get_all_suppliers(fetch_all: $fetchAll, supplier_type: $supplierType) {
      items {
        id
        name
      }
    }
  }
`;
export const GET_SUPPLIER_MEMO = gql`
  query GetSupplierMemo(
    $query: AdminGetSupplierMemoQueryInput!
    $offset: Int
    $limit: Int
    $fetch_all: Boolean
  ) {
    admin_get_supplier_memo(
      query: $query
      offset: $offset
      limit: $limit
      fetch_all: $fetch_all
    ) {
      items {
        id
        memo_number
      }
    }
  }
`;
const InlineMeleeOrderFragment = gql`
  fragment InlineMeleeOrderItem on OrderItem {
    assigned_to {
      id
      firstName
      lastName
      profile_image
    }
    buyer {
      id
      firstName
      company {
        id
        name
        purchasing_type
        qc_pref
      }
    }
    buy_price
    buy_price_confirmed
    carats
    canada_mark_required
    customer_comment
    order_number
    destination {
      id
      country
    }
    dtl_required
    expectedCollectionDate
    id
    invoice {
      id
    }
    Product {
      ... on Melee {
        ...MeleeFragment
        source
        location {
          id
          country
          name
        }
        IsParentMeleeCollection
      }
    }
    ProductType
    order {
      id
      order_date
      user_comment
    }
    parent_order_item {
      id
      order_number
    }
    order_number
    origin_country
    origin_city
    pieces
    return_option
    rupee_rate
    status
    transit_country
    wdcComment
    notes {
      ...AdminNote
    }
  }
  ${NoteFragment}
`;

export const GET_MELEE_ORDERS_BY_STATUS = gql`
  query GetMeleeOrdersByStatus(
    $offset: Int
    $origin_country: OriginCountry
    $status_key: OrderItemStatusKey!
    $query: AdminOrderItemQuery!
  ) {
    allOrderItems: orders_by_status(
      key: $status_key
      limit: 500
      offset: $offset
      origin_country: $origin_country
      query: $query
    ) {
      total_count
      items {
        ...InlineMeleeOrderItem
        Product {
          ... on Melee {
            original_carats_ordered
            child_melee_collections {
              id
              order_item {
                ...InlineMeleeOrderItem
              }
            }
          }
        }
        internal_order
      }
    }
  }
  ${MeleeFragment}
  ${InlineMeleeOrderFragment}
`;

export const GET_VIEW_MELEE_BY_ID = gql`
  query getMeleeViewQueryById($meleeofferId: ID!) {
    melee_by_id(id: $meleeofferId) {
      id
      status
      sourced_date
      consignment_period
      total_carats
      carats_ordered
      price_per_carat
      cost_price_per_carat
      price_per_carat
      location {
        id
      }
      carats
      ... on Melee {
        MeleeCertificate: certificate {
          id
          certNumber
        }
      }
    }
  }
`;
export const GET_EDIT_MELEE_BY_ID = gql`
  query getMeleeEditQueryById($meleeofferId: ID!) {
    melee_by_id(id: $meleeofferId) {
      id
      status
      type
      source
      sourced_date
      total_carats
      carats_ordered
      price_per_carat
      consignment_period
      cost_price_per_carat
      price_per_carat
      location {
        id
      }
      carats
      parcel
      supplierStockId
      ... on Melee {
        MeleeCertificate: certificate {
          id
          certNumber
          to_mm_size
          from_mm_size
        }
      }
    }
  }
`;
// Note: Any extra fields added to this query must also be added to the
// ADMIN_ADD_MELEE_ITEM mutation.
export const GET_MELEE_ADMIN_INVENTORY = gql`
  query getAllMeleeInventory(
    $query: AdminMeleeInventoryQueryInput
    $text: String
    $offset: Int
  ) {
    admin_get_melee_inventory(
      query: $query
      text: $text
      limit: 10
      offset: $offset
    ) {
      items {
        ...AdminMeleeRepoItemFragment
        ## This is to show the order count on repo page
        child_order_count
      }
      total_count
    }
  }
  ${AdminMeleeRepoItemFragment}
`;

export const GET_ALL_MELEE_ADMIN_INVENTORY = gql`
  query getAllMeleeInventory(
    $query: AdminMeleeInventoryQueryInput
    $text: String
    $offset: Int
  ) {
    admin_get_melee_inventory(
      query: $query
      text: $text
      fetch_all: true
      offset: $offset
    ) {
      items {
        ...AdminMeleeRepoItemFragment
        ## This is to show the order count on repo page
        child_order_count
      }
      total_count
    }
  }
  ${AdminMeleeRepoItemFragment}
`;

export const GET_MELEE_ADMIN_INVENTORY_FOR_STOCK_LINKING = gql`
  query getAllMeleeInventory(
    $query: AdminMeleeInventoryQueryInput
    $text: String
    $offset: Int
  ) {
    admin_get_melee_inventory(
      query: $query
      text: $text
      fetch_all: true
      offset: $offset
    ) {
      items {
        ...AdminSrLinkStockIdFragment
        ## This is to show the order count on repo page
        child_order_count
      }
      total_count
    }
  }
  ${AdminSrLinkStockIdFragment}
`;

export const GET_MELEE_PRICE_HISTORY_BY_ID = gql`
  query getMeleeOffersById($meleeofferId: ID!) {
    melee_by_id(id: $meleeofferId) {
      id
      melee_updates {
        id
        type
        value_name
        value_from
        value_to
        updatedAt
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_MELEE_ORDER_DETAILS_BY_ID = gql`
  query getMeleeOrdersById(
    $query: AdminMeleeOrdersQuery
    $offset: Int
    $fetch_all: Boolean
  ) {
    admin_get_melee_orders_by_query(
      query: $query
      offset: $offset
      limit: 10
      fetch_all: $fetch_all
    ) {
      type
      total_count
      items {
        id
        carats
        order_number
        order {
          id
          order_date
        }
        offer {
          id
        }
        child_order_items {
          id
          carats
          order_number
          order {
            id
            order_date
          }
        }
      }
    }
  }
`;

export const MELEE_SPECIAL_REQUESTS = gql`
  query getMeleespecialRequest($query: MeleeOrderRequestFilters) {
    admin_get_melee_special_requests(query: $query) {
      id
      createdAt
      shape
      cut
      color
      f_color
      clarity
      pieces
      price_per_carat
      stone_type
      admin_comment
      order_request_number
      customer_order_number
      source
      requester {
        id
        firstName
        lastName
        country
        city
        company {
          id
          name
        }
        email
      }
      assigned_to {
        id
        firstName
        lastName
        company {
          id
          name
        }
      }
      status
      OrderRequestHistoric {
        id
        carats
        pieces
        status
        source
        shape
        admin_comment
        buyer_comment
        order_request_ref_image
        customer_order_number
        ProductType
        cut
        color
        f_color
        clarity
        from_mm_size
        to_mm_size
        length
        width
        depth
        stone_type
        order_item {
          id
          order_number
          order {
            id
          }
        }
        updatedAt
      }
      OrderRequests {
        id
        sequence_number
        carats
        pieces
        status
        source
        length
        quoteSentAt
        width
        depth
        shape
        cost_price_per_carat
        sell_price_per_carat
        min_delivery_time
        max_delivery_time
        admin_comment
        melee {
          ... on Melee {
            price_per_carat
            ...MeleeFragment
          }
        }
        ProductType
        cut
        color
        f_color
        clarity
        from_mm_size
        to_mm_size
        stone_type
        order_item {
          id
          order_number
          order {
            id
          }
        }
        updatedAt
      }
      notes {
        id
        data
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          profile_image
        }
      }
    }
  }
  ${MeleeFragment}
`;

export const MELEE_SPECIAL_REQUESTS_DETAILS = gql`
  query getMeleespecialRequestDetails($query: MeleeOrderRequestFilters) {
    admin_get_melee_special_requests(query: $query) {
      id
      createdAt
      shape
      cut
      color
      f_color
      clarity
      pieces
      price_per_carat
      order_request_ref_image
      stone_type
      admin_comment
      buyer_comment
      order_request_number
      customer_order_number
      expected_delivery_date
      requester {
        id
        firstName
        lastName
        country
        city
        company {
          id
          name
        }
        email
      }
      assigned_to {
        id
        firstName
        lastName
        company {
          id
          name
        }
      }
      status
      OrderRequestHistoric {
        id
        carats
        pieces
        status
        source
        shape
        admin_comment
        buyer_comment
        order_request_ref_image
        customer_order_number
        ProductType
        cut
        color
        f_color
        clarity
        from_mm_size
        to_mm_size
        length
        width
        depth
        stone_type
        order_item {
          id
          order_number
          order {
            id
          }
        }
        updatedAt
      }
      OrderRequests {
        id
        sequence_number
        carats
        pieces
        status
        length
        width
        quoteSentAt
        depth
        source
        shape
        min_delivery_time
        max_delivery_time
        admin_comment
        cost_price_per_carat
        sell_price_per_carat
        melee {
          ... on Melee {
            price_per_carat
            ...MeleeFragment
          }
        }
        ProductType
        cut
        color
        f_color
        clarity
        from_mm_size
        to_mm_size
        stone_type
        order_item {
          id
          order_number
          order {
            id
          }
        }
        updatedAt
      }
      notes {
        id
        data
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          profile_image
        }
      }
    }
  }
  ${MeleeFragment}
`;

export const GET_MELEE_REQUESTERS = gql`
  query getMeleespecialRequesters($query: MeleeOrderRequestFilters) {
    admin_get_melee_order_requesters(query: $query) {
      id
      firstName
      lastName
    }
  }
`;
export const FETCH_DYNAMIC_MM_SIZE_OPTIONS = gql`
  query getDynamicSizeFilterOptions(
    $filters: [MeleeFilterFields!]!
    $melee_type: MeleeType
    $melee_shape: [Shape!]!
    $melee_status: [MeleeStatus!]!
    $melee_source: [MeleeSource!]!
  ) {
    get_melee_dynamic_filters(
      filters: $filters
      melee_type: $melee_type
      melee_shape: $melee_shape
      melee_status: $melee_status
      melee_source: $melee_source
    ) {
      mm_size {
        from_mm_size
        to_mm_size
      }
    }
  }
`;
