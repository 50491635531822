import { gql } from '@apollo/client';
export const CompanyInfoFragment = gql`
  fragment CompanyInfoFragment on Company {
    id
    is_owner
    name
    code_disabled
    diamonds {
      total_count
    }
    company_banner
    main_location
    loupe360_url
    locations {
      id
      name
      country
      city
    }
    signup_coupon_info {
      supplier {
        id
        name
      }
      uses_left
    }
    inventory_type
    free_shipments_left
    nivoda_express_enabled
    company_settings {
      CompanyId
      holds_enabled
      accept_holds
      hold_hours
      supersaver_enabled
      hide_customer_names
      nivoda_express_supplier
      auto_fetch_feeds
      display_supplier_name
      self_serve_holds
      self_serve_po
      is_mor_enabled
      is_supplier_details_enabled
      memo_enabled
      memo_stone_checkout_limit
      memo_duration
      customer_order_number_req
    }
    market_pay_active
    market_pay_eligible
    nivoda_express_enabled
    credit_limit
    credit_consumed
    account_limit
    account_limit_consumed
    ip_active
    default_credit_enabled
    disable_checkout
    market_pay_limit
    market_pay_limit_consumed
    billing_currency_preference
    cleared_for_credit
    credit_default_days
    all_in_pricing
    credit_default_days
    charge_last_mile_fee
    country_of_incorporation
    qc_pref
    is_airwallex_enabled
    memo_return_fee
    memo_return_penalty
  }
`;

export const UserProfileDetailsFragment = gql`
  fragment UserProfileDetailsFragment on User {
    id
    steps_required
    firstName
    lastName
    profile_image
    default_currency
    role
    preferred_language
    email
    phone1
    default_sort_type
    default_sort_direction
    country
    city
    postalCode
    phone2
    address
    email_notifications
    subtype
    exchange_rate {
      id
      name
      to_USD
    }
    company {
      id
      ...CompanyInfoFragment
    }
  }
  ${CompanyInfoFragment}
`;

export const AdminUserProfileDetailsFragment = gql`
  fragment UserProfileDetailsFragment on User {
    id
    firstName
    lastName
    profile_image
    default_currency
    role
    preferred_language
    email
    phone1
    country
    city
    postalCode
    phone2
    address
    company {
      id
      is_owner
      name
      company_banner
      main_location
      loupe360_url
      locations {
        id
        name
        country
        city
      }
    }
  }
`;

export let user_fragment = gql`
  fragment Full_User on User {
    id
    firstName
    lastName
    country
    city
    postalCode
    phone1
    phone2
    email
    skype
    address
    default_currency
    email_notifications
    preferred_language
    exchange_rate {
      id
      name
      to_USD
    }
  }
`;
