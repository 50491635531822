import { gql } from '@apollo/client';
import { MeleeFragment } from './MeleeFragment';
import { NoteFragment } from './NoteFragment';

const AccountingOrderItemFragment = gql`
  fragment AccountingOrderItem on OrderItem {
    id
    order {
      id
      order_date
    }
    status
    order_number
    FbnType
    buy_price
    buy_discount
    origin_country
    buy_price_confirmed
    nivoda_markup
    customer_vat_rate
    wdcComment
    comment
    expectedCollectionDate
    collectedDate
    transitArrivalDate
    rupee_rate
    destination {
      id
      country
    }
    notes {
      ...AdminNote
    }
    supplier_payment {
      type
      payment_date
    }
    Product {
      ... on Diamond {
        id
        supplierStockId
        certificate {
          id
          lab
          certNumber
          shape
          carats
          color
          clarity
          floCol
          floInt
          cut
          polish
          symmetry
        }
        location {
          id
          country
        }
        supplier {
          id
          name
        }
      }
      ... on Melee {
        ...MeleeFragment
        total_price
      }
      ... on Gemstone {
        id
        supplierStockId
        GemstoneCertificate: certificate {
          id
          lab
          certNumber
          shape
          carats
          color
          clarity
          gemType
          cut
        }
        location {
          id
          country
        }
        supplier {
          id
          name
        }
      }
    }
    ProductType
    carats
    pieces
    buyer {
      id
      company {
        id
        name
        invoice_currency
      }
    }
  }
  ${MeleeFragment}
  ${NoteFragment}
`;

export const SuppliersToPayOrderItemListFragment = gql`
  fragment SuppliersToPayOrderItemList on AdminOrderItemsToPay {
    items {
      company {
        id
        name
        type
      }
      items {
        ...AccountingOrderItem
      }
      total_count
    }
    previous_offset
    total_amount
    total_count
  }
  ${AccountingOrderItemFragment}
`;

export const SuppliersToPayINVOrderItemListFragment = gql`
  fragment SuppliersToPayINVOrderItemList on AdminInvoicesToPayList {
    invoices_to_pay_total_amount
    items {
      id
      invoice_link
      currency
      exchange_rate
      usd_amount
      due_date
      invoice_number
      name_on_invoice
      vat_number
      total_amount
      total_amount_tax
      settled_amount
      tds_amount
      supplier_payable_amount
      invoice_nivoda_reference
      invoice_from {
        id
        name
      }
      items {
        ...AccountingOrderItem
      }
    }
    total_count
  }
  ${AccountingOrderItemFragment}
`;

export const AwaitingPaymentOrderItemListFragment = gql`
  fragment AwaitingPaymentOrderItemList on AdminInvoicesAwaitingPaymentList {
    invoices_total_amount_tax
    items {
      id
      invoice_number
      invoice_id
      invoiced_at
      due_date
      incentive_pay_charge
      from_country
      currency
      total_amount
      total_amount_tax
      invoice_tax_rate
      exchange_rate
      type
      settled_amount
      is_bad_debt
      credit_given_by
      mp_advance_requested
      mp_advance_recieved
      payment_mode
      invoiced_company {
        id
        name
        market_pay_active
        disable_checkout
        is_airwallex_enabled
        airwallex_transactions {
          id
          ccy
          amount
          balance
          reference
          createdAt
        }
        credit_notes {
          id
          invoice_number
          invoice_id
          due_date
          currency
          total_amount
          total_amount_tax
          settled_amount
          type
          invoiced_at
        }
      }
      items {
        ...AccountingOrderItem
        base_sell_price
        base_sell_discount
      }
    }
    total_count
  }
  ${AccountingOrderItemFragment}
`;

export const ToInvoiceOrderItemListFragment = gql`
  fragment ToInvoiceOrderItemList on OrderByStatusList {
    previous_offset
    total_amount
    total_count
    items {
      ...AccountingOrderItem
      base_sell_price
      base_sell_discount
      shippingDate
    }
  }
  ${AccountingOrderItemFragment}
`;
