const APP_BASE_URL = process.env.REACT_APP_API_BASE || 'http://localhost:3000';
const CUSTOMER_FEED_URL = process.env.REACT_APP_FEED_API_URL;
const FFS_BASE_URL =
  process.env.REACT_APP_GATEWAY_SERVICE || 'https://dev-gateway.nivodaapi.net';
const lastItem = FFS_BASE_URL.charAt(FFS_BASE_URL.length - 1);
const trailingDash = '/';

/**
 * @desc Platform infrastructure constants
 *
 */
export const INFRA = Object.freeze({
  ADMIN_STOCK_UPLOAD_URL: `${APP_BASE_URL}/upload/admin_supplier_csv`,
  ADMIN_INVOICE_UPLOAD_URL: `${APP_BASE_URL}/upload/admin_invoice/invoiceId/reviewStatus`,
  ADMIN_INVENTORY_UPLOAD_URL: `${APP_BASE_URL}/upload/admin_upload_melee_inventory/supplierId`,
  ADMIN_PRICE_UPLOAD_URL: `${APP_BASE_URL}/upload/admin_update_melee_inventory`,
  SUPPLIER_INVOICE_UPLOAD_URL: `${APP_BASE_URL}/upload/supplier_invoice/invoiceId`,
  SUPPLIER_INVOICE_REUPLOAD_URL: `${APP_BASE_URL}/upload/supplier_invoice_reupload/invoiceId`,
  SUPPLIER_OCR_SERVICE_URL: `${APP_BASE_URL}/upload/supplier_invoice_fields`,
  APP_BASE_URL: APP_BASE_URL,
  DEV_API_HOST: `${APP_BASE_URL}/graphql`,
  IMAGE_UPLOAD_URL: `${APP_BASE_URL}/upload/diamond_image`,
  SR_REFERENCE_IMAGE_UPLOAD_URL: `${APP_BASE_URL}/upload/melee_order_request_image`,
  SUPPLIER_IMAGE_UPLOAD_URL: `${APP_BASE_URL}/upload/supplier-diamond-images`,
  SUPPLIER_STOCK_UPLOAD_URL: `${APP_BASE_URL}/upload/supplier_csv/REPLACE/category`,
  USER_PROFILE_IMAGE_UPLOAD_URL: `${APP_BASE_URL}/upload/userprofile`,
  GIA_REF_IMAGE_UPLOAD_URL: `${APP_BASE_URL}/upload/order_request_image`,
  CUSTOMER_FEED_URL: `${CUSTOMER_FEED_URL}/feeds-api`,
  FEATURE_FLAGGING_SERVICE_URL: `${FFS_BASE_URL}${
    lastItem === trailingDash ? '' : '/'
  }feature_flagging/isAllowed`,
  EXPRESS_CONSIGNMENT_FORM_UPLOAD_URL: `${APP_BASE_URL}/upload/express_request_consignment_form`,
  SHIPMENT_COST_UPLOAD_URL: `${APP_BASE_URL}/upload/shipment_cost`,
});
