import { NivodaExpressRequestsStatus } from 'constants/expressRequests';
import { OrderItemStatus, ProductTypes } from '../../constants';
import { appEnv } from '../../utils';
import { utils } from '@nivoda/common';
import { ShipmentTabURLs, ShipmentStatus } from 'constants/shipment';

/**
 * @summary centralize global / component level configurations
 *  1. cart page config for customer and admin-customer
 *  2. integration config for 3rd party extensions
 *  3. Holds feature config to enable it for specified user companies
 *  4. supersaver config to enable it for specified user companies
 * @author Abhishek Prajapati <abhi.chandresh@gmail.com>
 **/
export const config = Object.freeze({
  search: Object.freeze({
    product: [ProductTypes.DIAMOND, ProductTypes.MELEE], // enabled products on platform
  }),
  cart: Object.freeze({
    enableInvoiceCurrency: false,
    enableQCComment: true,
  }),
  integration: {
    intercomSettings: {
      enableOnDevENV: true, // set this to true to test/enable intercom on local
      HUBSPOT_PORTAL_ID: process.env.REACT_APP_HUBSPOT_PORTAL_ID || '21709827', // sandbox env id
    },
  },
  nivodaExpress: {
    allowedSidebar: ['SOURCE_STONES', 'GLOBAL_INVENTORY', 'EXPRESS_REQUESTS'],
    permissions: { view: 'view_express_menu' },
    inventory: {
      permissions: { view: 'view_global_inventory_page' },
      isEnable: (user) => {
        let canSupplierAccessGlobalInventory = false;
        const userCompanyID = user && user.company ? user.company.id : null;
        const isRoleAdmin = user ? user.role === 'ADMIN' : false;
        const isUserRoleSupplier = user ? user.role === 'SUPPLIER' : false;

        if (user && user.company && user.company['company_settings']) {
          // Global inventory will be visible to only those supplier
          // whose nivoda_express_supplier flag is true in company setting
          // This can be set by nivoda admins from --> https://nivoda.net/admin/companies
          // OR https://nivoda.net/admin/companies/[supplierCompanyId]
          // On local enable the global inventory from --> http://localhost:3001/admin/companies
          // OR http://localhost:3001/admin/companies/[supplierCompanyId]
          canSupplierAccessGlobalInventory =
            user.company['company_settings'].nivoda_express_supplier;
        }

        return appEnv.isProd
          ? !userCompanyID && typeof userCompanyID !== 'string'
            ? false
            : isUserRoleSupplier
            ? canSupplierAccessGlobalInventory
            : isRoleAdmin
          : isUserRoleSupplier
          ? canSupplierAccessGlobalInventory
          : isRoleAdmin;
      },
    },
    expressRequests: {
      tabs: [
        NivodaExpressRequestsStatus.REQUESTED_STONES,
        NivodaExpressRequestsStatus.PROCESSING_STONES,
        NivodaExpressRequestsStatus.IN_EXPRESS_INVENTORY,
        NivodaExpressRequestsStatus.PENDING_RETURN,
        NivodaExpressRequestsStatus.RETURNED_TO_SUPPLIER,
      ],
    },
  },
  holds: Object.freeze({
    adminDefaultDuration: 24,
    allowAutoResponseFeature: true, // To enable or disable the auto accept or reject hold request for supplier
    allowedProductTypes: [ProductTypes.DIAMOND], // enabled products on platform
    allowedUIDs: process.env.REACT_APP_H_WLC
      ? process.env.REACT_APP_H_WLC.split(',')
      : [], // Pass user id's to enable it for specific users
    disableAddToCart: false,
    defaultDuration: 24,
    /**
     * Checks if the hold feature is currently enabled/disabled
     * @param user {{}} user Object
     * @param hasCompanyCheck {boolean} if true then will read the company values form the `REACT_APP_H_WLC`
     *                                  env variable else `REACT_APP_H_WLC` will be ignored
     * @returns {boolean}
     */
    isEnable: (user, hasCompanyCheck = false) => {
      const { is_user_in_review } = utils.getUserReviewStatus(user);
      let canSupplierAccessHold = false;
      let canCustomerAccessHold = !is_user_in_review;
      const userCompanyID = user && user.company ? user.company.id : null;
      const isRoleAdmin = user ? user.role === 'ADMIN' : false;
      const isUserRoleSupplier = user ? user.role === 'SUPPLIER' : false;

      if (user && user.company && user.company['company_settings']) {
        // Hold will be visible to only those supplier who accept hold
        // This can be set by nivoda admins from --> https://nivoda.net/admin/companies
        // OR https://nivoda.net/admin/companies/[supplierCompanyId]
        // On local enable the hold from --> http://localhost:3001/admin/companies
        // OR http://localhost:3001/admin/companies/[supplierCompanyId]
        canSupplierAccessHold = user.company['company_settings'].accept_holds;

        canCustomerAccessHold =
          user.company['company_settings']['holds_enabled'];
      }

      return appEnv.isProd
        ? !userCompanyID && typeof userCompanyID !== 'string'
          ? false
          : isUserRoleSupplier
          ? canSupplierAccessHold
          : isRoleAdmin ||
            (hasCompanyCheck && !is_user_in_review && canCustomerAccessHold)
        : isUserRoleSupplier
        ? canSupplierAccessHold
        : isRoleAdmin ||
          (hasCompanyCheck && !is_user_in_review && canCustomerAccessHold);
    },
    showSummarySection: false,
    useDateAndTimePickerForCreation: false,
  }),
  order: Object.freeze({
    admin: {
      status: 'ACTIVE',
      tabs: [
        'PRICE_CALCULATOR',
        'MARKET_PAY_ORDER_STATUS',
        'ADD_STONE',
        'ADD_MANUAL_CERTIFICATE',
        'CREATE_ORDER',
        OrderItemStatus.AWAITING_PAYMENT,
        OrderItemStatus.WAITING_FOR_CUSTOMER,
        OrderItemStatus.PURCHASE_ORDER,
        OrderItemStatus.CONFIRMED,
        OrderItemStatus.IN_TRANSIT,
        OrderItemStatus.READY_TO_COLLECT,
        OrderItemStatus.READY_TO_TAG,
        OrderItemStatus.READY_TO_SHIP,
        OrderItemStatus.READY_TO_QC,
        OrderItemStatus.EXPRESS_READY_TO_COLLECT,
      ],
    },
  }),
  officeOrder: Object.freeze({
    admin: {
      status: 'ACTIVE',
      tabs: [
        ShipmentStatus.OFFICE_INCOMING,
        ShipmentStatus.TO_PROCESS,
        ShipmentStatus.READY_FINAL_DELIVERY,
        ShipmentStatus.SHIPPED_FINAL_DELIVERY,
        ShipmentStatus.DELIVERED,
        ShipmentStatus.TO_RETURN,
        ShipmentStatus.SBS,
      ],
    },
  }),
  shipments: Object.freeze({
    admin: {
      status: 'ACTIVE',
      tabs: [ShipmentTabURLs.ALL_SHIPMENTS, ShipmentTabURLs.CREATE_SHIPMENTS],
    },
  }),
  supersaverConfig: {
    isSSUser: (user) => {
      const userCompanyID = user && user.company ? user.company.id : null;
      const isRoleAdmin = user ? user.role === 'ADMIN' : false;
      const isRoleSupplier = user ? user.role === 'SUPPLIER' : false;

      const user_supersaver_enabled =
        user &&
        user.company &&
        user.company.company_settings &&
        user.company.company_settings.supersaver_enabled;

      return appEnv.isProd
        ? !userCompanyID && typeof userCompanyID !== 'string'
          ? false
          : isRoleAdmin || user_supersaver_enabled
        : isRoleSupplier
        ? user_supersaver_enabled
        : false;
    },
  },
});

/**
 * @type {string} Custom flag url provider
 * @see https://github.com/catamphetamine/react-phone-number-input
 */
export const FLAG_URL =
  'https://purecatamphetamine.github.io/country-flag-icons/3x2/{XX}.svg';

export { adminAllowedUserConfig } from './admin-allowed-user-config';
export { getMeleeConfig, AdminMeleeConfig } from './melee-config';
export { holdHourOptions } from './holdHourOptions';
